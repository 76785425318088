//Only components as k-header, k-carousel, k-product, etc
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";

.header {
    $context: &;
    width: 100%;
    height: 100px;
    background: rgba(white, 0.7);
    &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &_logo {
        &_img {
            width: 190px;
            height: 100%;
            @include media-breakpoint-down (xl) {
                width: 120px;
            }
        }
    }
    &_nav {
        display: flex;
        font-size : 18px;
        @include media-breakpoint-down (xl) {
            font-size : 15px;
        }
    }
    &_ul {
        gap: 12px;
        display: inline-flex;
        &_sub {
            z-index: 100;
            background: $main-color;
            padding: 15px 0px 30px 0px;
            li {
                display: flex;
                font-weight: 100;
                color: white;
                a {
                    width: 100%;
                    padding: 0 50px 0 15px;
                }
            }
        }
    }
    &_li {
        font-weight: 600;
        position: relative;
        padding: 10px;
        font-size: 11px;
    }

    li ul {
        width: fit-content;
        position: absolute;
        top: 100%; left: 0;
        display: none;
        color:white;
    }

    li ul li {
        @extend .transition;
        &:hover {
            background: #B11C28;
        }
    }

    li:hover ul,
    li:focus-within ul {
        display: block;
    }

    @media (max-width: $mobile-breakpoint) {
        display: none;
        &_mobile {
            display: block !important;
        }
    }

    &_mobile {
        $ctx: &;
        height: 100px;
        display: none;
        padding: 20px 10px;
        &, * {
            @extend .transition;
        }
        .is-active .hamburger-inner {
            &, &::before, &::after {
                background-color: white;
            }
        }
        &_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &_locale {
            z-index: -1;
            opacity: 0;
            position: absolute;
        }
        img {
            width: 150px
        }
        &--open {
            width: 90vw;
            z-index: 1001;
            position: absolute;
            background: $dark-color;
            #{$ctx}_wrapper {
                width: 100%;
            }
            #{$ctx}_locale {
                z-index: -1;
                opacity: 1;
                position: relative;
            }
            #{$ctx}_img {
                left: 50%;
                position: absolute;
                transform: scale(0.9) translateX(-50%);
                img {
                    filter: brightness(100);
                }
            }
        }
    }

    // Only shows the mobile header
    &--mobile-on {
        @media (max-width: $mobile-breakpoint) {
            display: flex !important;
        }
    }

    &--mobile {
        display: none;
        z-index: 1000;
        left: 0; top: 0;
        position: fixed;
        flex-direction: column;
        background: $dark-color;
        width: 100vw; height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;

        @media (max-width: $mobile-breakpoint) {
            display: none;
        }

        li ul {
            top: unset;
            position: relative;
            display: block !important;
        }

        color: rgba(white, 0.5);
        #{$context}_ul {
            gap: 2px;
            display: flex;
            font-size: 20px;
            flex-direction: column;
            &_sub {
                padding: 0;
                background: transparent !important;
                li {
                    font-size: 17px;
                    a {
                    }
                }
            }
        }
    }

    &_social {
        @extend .transition;
        &:hover, &:focus-within {
            color: $main-color;
            .header_social_title {
                border-color: $main-color;
            }
        }

        &_container {
            gap: 15px;
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        &_icon {
            @extend .text-size_md-icon;
        }
        &_title {
            @extend .transition;
            border-bottom: 2px solid rgba(white, 0.5);
        }
    }

    &_footer {
        width: 100%;
        border-top: 2px solid rgba(white, 0.2);
    }
}

.footer {
    min-height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    // background-color: $main-color;
    background-position: center center;
    &_logo {
        width: 100%;
        margin: auto;
        max-width: 200px;
    }
    @include media-breakpoint-down (md) {
        height: auto;
        padding-top: 50px;
        margin-top: -50px;
    }
    @include media-breakpoint-down (sm) {
        padding-top: 200px;
        margin-top: -150px;
        text-align: center;
    }
}

.center-icon {
    position: relative;
    width: 40px; height: 40px;
    @extend .button-reset;
    
    &--sm {
        width: 10px; height: 10px;
    }
    &--medium {
        width: 30px; height: 30px;
    }
    &--xxl {
        width: 110px; height: 110px;
    }
    &--round {
        overflow: hidden;
        border-radius: 100%;
    }
    &--hover {
        @extend .transition;
        &:hover {
            background: $shade-color;
        }
    }
    & > * {
        position: absolute;
        @extend %absolute-fully-centered;
    }
    &_text {
        letter-spacing: 0.1em;
    }
}

.k-icon-fix-pos {
    svg {
        top: 0px !important;
    }
}

.primary-button {
    gap: 0 30px;
    height: 40px;
    display: flex;
    border: none;
    outline: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    align-items: center;
    @extend .hover_opacity;
    background-color: $main-color;
    
}

.blog {
    &_mini {
        $ctx: &;
        // width: 90vw;
        // max-width: 470px;
        height: 590vw;
        max-height: 590px;
        position: relative;
        border-radius: 15px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 0px 17px 7px rgba(0,0,0,0.75);
        &, img {
            @extend .transition;
        }
        &:hover {
            background-color: $light-color;
            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75) !important;
            #{$ctx}_body {
                background-color: $light-color;
            }
            img {
                filter: grayscale(0.5);
            }
        }
        &--variant {
            box-shadow: unset;
            background-color: $light-color;
            #{$ctx}_body {
                text-align: left;
                align-items: start;
                justify-content: start;
            }
            &:hover {
                box-shadow: 0px 0px 17px 7px rgba(0,0,0,0.75) !important;
                #{$ctx}_body {
                    background-color: white;
                }
                img {
                    filter: unset;
                }
            }
            #{$ctx}_img {
                img {
                    padding: 20px;
                    @extend .image-cover;
                }
            }
        }

        &_img {
            width: 100%;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            img {
                @extend .image-cover;
            }
            height: 65%;
            overflow: hidden;
        }
        &_body {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            height: 35%;
            padding: 10px 15px;
            text-align: justify;
            @extend .transition;
            @extend .flex-center;
            flex-direction: column;
        }
        &_listing {
            gap: 10px;
            display: flex;
            align-items: center;
            overflow: hidden;
            justify-content: space-between;
            @include media-breakpoint-down (md) {
                justify-content: center;
            }
        }
        &_btn {
            z-index: 10;
            position: absolute;
            perspective: 1000px;
            top: 100%; left: 50%;
            justify-content: center;
            height: 30px; width: 60px;
            transition: transform 0.6s ease;
            transform: translate(-50%, -50%) rotateX(-90.1deg);
        }
        
        &:hover {
            #{$ctx}_btn {
                transform: translate(-50%, -50%) rotateX(0deg);
            }
            box-shadow: 0px 0px 17px 7px rgba(0,0,0,0.75);
        }
    }
}

.newsletter-input {
    width: 360px;
    height: 40px;
    display: flex;
    max-width: 70vw;    
    align-items: center;
    .is-error {
        input, .el-input-group__append {
            border-color: #B11C28 !important;
        }
    }
    .el-form {
        width: 100%;
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .el-input-group__append {
        background-color: transparent;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .el-input {
        padding: 0px 0px;
    }
    input {
        border-right: none;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

.contact-form {
    color: $shade-color;
    input, textarea {
        border-radius: 0;
        background-color: transparent;
    }
    textarea {
        border: 1px solid rgba($shade-color, 0.3);
    }
    input {
        border: 0;
        border-bottom: 1px solid rgba($shade-color, 0.3);
        &::placeholder {
            text-align: center;
            color: $shade-color;
        }
    }

    .el-form-item__label {
        float: unset;
        text-align: center;
        color: $shade-color;
    }

    .el-checkbox__label {
        font-weight: 100;
        @extend .text-size_17;
    }

    .el-checkbox {
        margin: 0 30px;
        display: flex;
        span {
            white-space: normal;
            pointer-events: none;
        }
        .el-checkbox__inner {
            background-color: transparent;
            border: 1px solid rgba($shade-color, 0.3);
        }
    }

    a {
        font-weight: 600;
    }
}