
<template>
    <div class="newsletter-input">
        <el-form :model="form"  class="row ">
            <el-form-item prop="email" class="input-container col-12">
                <el-input v-model="form.email" class="col-10" type="text" placeholder="Email"></el-input>
                <el-button  class="col-1 k-icon-fix-pos" style=" height:40px; top:2px; left:-8px; border-left:none;  border-top-right-radius: 10px;
                border-bottom-right-radius: 15px;" @click="submitForm" type="button">
                    <KIcon class="text-color_black" icon="arrowRight" />&nbsp;&nbsp;
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<style scoped>
.input-container {
    display: flex;
    justify-content: space-between;
}
</style>

<script>
import KIcon from '../components/icons/KIcon';
import axios from 'axios'; // Asegúrate de importar axios
import Swal from 'sweetalert2';



export default {
    components: {
        KIcon
    },
    data () {
        return {
            form: {
                email: "",
            },
        }
    },
    methods: {
        async submitForm(event) {
            console.log("submitForm called"); // Agrega esta línea
            event.preventDefault(); // Evitar recarga de página

            try {
                const response = await axios.post('/send-newsletter-mail', {
                    email: this.form.email,
                });

                this.form.email = "";
                Swal.fire({
                    icon: 'success',
                    title: '¡Correo enviado con éxito!',
                    text: 'Te has suscrito al Newsletter',
                }).then(() => {
                    location.reload(); // Recargar la página después de la notificación
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: '¡Error al enviar el formulario!',
                    text: 'Hubo un problema al procesar tu solicitud.',
                });
            }
        }
    }
}
</script>