<template>
    <div class="color-dark">
        <el-form  class="contact-form" :model="form"  >
            <el-form-item prop="name">
                <el-input v-model="form.name" name="name" type="text" placeholder="Nombre completo*" requiered/>
            </el-form-item>
            <el-form-item prop="company">
                <el-input v-model="form.company" name="company" type="text" placeholder="Empresa" requiered/>
            </el-form-item>
            <el-form-item prop="email">
                <el-input v-model="form.email" type="text" name="email" placeholder="Email*" requiered/>
            </el-form-item>
            <el-form-item prop="phone">
                <el-input v-model="form.phone" type="text"  name="phone" placeholder="Teléfono (10 digitos)*"  requiered/>
            </el-form-item>
            <el-form-item prop="message" label="Mensaje" >
                <el-input v-model="form.message" type="textarea" name="message" rows="5"/>
            </el-form-item>

            <el-form-item prop="accept">
                <el-checkbox class="mt-2">
                    <span>He leído y Acepto que Etiquetas Electrónicas de Occidente protegerá mis datos como se detalla en el </span>
                    <a href="">Aviso de Privacidad</a>
                </el-checkbox>
            </el-form-item>

            

            <button type="button" class="primary-button mx-auto mt-4" @click="submitForm">
                <span class="text-size_18 p-1">Enviar</span>
                <div class="">
                    <img :src="`../build/images/izq.svg`" width="25" height="25" alt="Arrow Right Icon" style="margin-left:-20px;" />
                </div>
            </button>

            <div class="mt-4 text-size_14">
                <span>*Campos obligatorios</span>
            </div>
        </el-form>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data () {
        return {
            form: {
        name: '',
        company:'',
        email: '',
        phone:'',
        message:'',
            },

        }
    },
    methods: {
        async submitForm(event) {
        event.preventDefault(); // Evitar recarga de página

        try {
            const response = await axios.post('/send-contact-mail', {
            name: this.form.name,
            company: this.form.company, 
            email: this.form.email,
            phone: this.form.phone,
            message: this.form.message,
        });
            console.log('Formulario enviado con éxito', this.form.name,
            this.form.company, 
            this.form.email,
            this.form.phone,
            this.form.message);

            // Muestra una alerta de éxito personalizada
            Swal.fire({
                icon: 'success',
                title: '¡Correo enviado con éxito!',
                text: 'Gracias por ponerte en contacto con nosotros.',
            });

            // Realiza cualquier acción necesaria después de enviar el formulario
        } catch (error) {
            console.error('Error al enviar el formulario', error);

            // Muestra una alerta de error personalizada
            Swal.fire({
                icon: 'error',
                title: '¡Error al enviar el formulario!',
                text: 'Hubo un problema al enviar el correo. Por favor, inténtalo nuevamente.',
            });
        }
    }
    }
}
</script>
<style scoped>
::placeholder {
    color: black;
}
</style>