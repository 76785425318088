

@media (min-width: 20px) and (max-width:500px) {

  body {
    -ms-overflow-style: none;  /* Para IE y Edge */
    scrollbar-width: none;     /* Para Firefox */
    overflow-y: scroll;        /* Agrega desplazamiento vertical */
  }
  
  /* Ocultar la barra de desplazamiento en Firefox */
  body::-webkit-scrollbar {
    display: none;
  }
  .principal{
    margin-top: 5px !important;
  }
  .myCategory strong{
    font-size: 12px !important;
  }
  .content-g2{
    width: 100%!important;
    font-size: 18px !important;
  }

  .content-g3{
    width: 100%!important;
  }
  #contacto .font-weight_medium{
    font-size: 18px !important;
  }
  .mapas{
    margin-left: -10% !important;
  }
  .principal #first_counter .title{
    font-size: 36px !important;
  }

  .principal #first_counter .text{
    font-size: 22px !important;
  }
  .principal #second_counter2 .title{
    font-size: 36px !important;
  }
  .principal #second_counter2 .text{
    font-size: 22px !important;
  }
  
  .principal #third_counter3 .title{
    font-size: 36px !important;
  }
  .principal #third_counter3 .text{
    font-size: 22px !important;
  }
  .principal #fourth_counter .title{
    font-size: 36px !important;
  }
  .principal #fourth_counter .text{
    font-size: 22px !important;
  }

  .nuevos .col-9{
  width:90% !important;
  }
  .timeline-pc{
    display: none;
  }
  .txt{
    text-align: center;
  }
  .timeline-mobile{
    display: block !important;
  }
  .quien{
    position: absolute;
    margin-left: 3%!important;
    margin-top: 80%!important;
    font-size: 23px!important;
  }
  .contenedor{ 
    width: 100% !important;
  }
  .contenedor #first_counter1{
    width: 100% !important;
  }
  .textos{ width: 100% !important;}
  .textos .text-center{
    text-align: justify !important;
   
  }
  .home {
    &_portfolio {
      gap: 0 5px !important;
        &_item {
            $ctx: &;
            width: 100% !important;
            max-width: 100vw !important;
            height: 400px;

            img {
              width: 95%!important;
              height: 400px !important;
              padding-right: 30px !important;
            }
            &_title {
                top: 20% !important;
                left: 56% !important;
                height: 50% !important;
                width: 80% !important;
                opacity: 0.6; 
                text-align: center;
                position: absolute;
                transform: rotate(90deg) translate(0%, 100px) ;
            }
            &_hover {
              color: white;
              text-align: left;
              position: absolute;
              top: 50%; 
              left: 0%;
              width: calc(100% - 60px);
              background: #E66D64;
              transform: translate(-100%, -50%);
              & > * {
                  padding:30px 55px 5px 30px !important;
              }
        }
      }
    }
    &_counters {
      
      position: absolute;
      width: 100%; height: 150px !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: rgba($main-color, 0.9);
      &_body2 {
        color: white;
        text-align: center;
        padding: 10px 0;
        .title {
            font-weight: 800;
            font-size: 40px !important;
        }
        
        .text {
            font-weight: 400;
        }
    }
  }    
  &_solution {
    display: flex;
    font-size: 12px !important;
    align-items: center;
    justify-content: space-between;
    &_item {
        $ctx: &;
        display: flex;
        align-items: center;
        flex-direction: column;
        .center-icon {
            background-color: $light-color;
        }
        &:hover {
            .center-icon {
                background-color: $main-color;
            }
            #{$ctx}_action {
                opacity: 1;
            }
        }
        &_action {
            opacity: 0;
        }
        
    }
    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 70px;
        &_item {
            .center-icon {
                width: 70px; height: 70px;
            }
            &_action {
                width: 20px !important; 
                height: 20px !important;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        gap: 30px;
    }
}
}

.solution .img-g{
  width: 100% !important;
}

.solution .content-g{
  width: 100% !important;
}
.img-g{
  width: 100% !important;
}

.medida{
  width: 50% !important;
  display: block;
  margin: 0 auto;
}
.content-g{
  width: 100%!important;
}.col-6{
  width: 100% !important;
}
.certify {
 display: block !important;
} 

.certify2 {
 display: none !important;

 } 

.img-a{
  // width: 50%;
  width: 100% !important;
  margin: 0 auto;
}

.year2 {
  text-align: center;
  border: 1px solid gray;
  border-radius: 5px;
}

.year2:hover {
  background-color: #c91634;
  color: white;
 
}
.mobile-content{
  background-color: #c91634;
  color: white;
  margin-top: 20px;
  padding: 5px;
  border-radius: 10px;
}

}

.timeline-mobile{
  display: none;
}


.medida{
  width: 100% ;
}

.certify2 {
  display: block;
 }  
.certify{
 display: none;
} 
.img-a{
  width: 50%;
}
.img-g{
  width: 50%;
}
.content-g{
  width: 50%;
}
.home {
    &-carousel {
        min-height: 400px;
        &_text {
            left: 50%;
            width: 80vw;
            z-index: 11;
            bottom: 80px;
            font-size: 500;
            text-align: center;
            position: absolute;
            transform: translateX(-50%);
        }
        &_image {
            object-fit: cover;
            height: 100%; width: 100%;
            &_container {
                height: 100%; width: 100%;
            }
        }
    }

    &_counters {
        left: 0;
        z-index: 2;
        top: -30px;
        margin: 0;
        position: absolute;
        width: 100%; height: 70px !important;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: rgba($main-color, 0.9);
        #third_counter, #second_counter {
            &::before {
                content: "";
                position: absolute;
                width: 50%; height: 1px;
                background-color: rgba(white, 0.3);
                // @include media-breakpoint-down (lg) {
                //     display: none;
                // }
            }
        }
        // #first_counter {
        //     @include media-breakpoint-down (lg) {
        //         border-bottom: 1px solid rgba(white, 0.3);
        //     }
        // }
        #second_counter {
            position: relative;
            border-left: 1px solid rgba(white, 0.3);
            &::before {
                top: 0; right: 0;
            }
            // @include media-breakpoint-down (lg) {
            //     border-bottom: 1px solid rgba(white, 0.3);
            // }
            // @include media-breakpoint-down (md) {
            //     border-left: unset;
            // }
        }
        #third_counter {
            position: relative;
            border-left: 1px solid rgba(white, 0.3);
            border-right: 1px solid rgba(white, 0.3);
            &::before {
                top: 0; left: 0;
            }
            // @include media-breakpoint-down (lg) {
            //     border-left: unset;
            // }
            // @include media-breakpoint-down (md) {
            //     border-right: unset;
            //     border-bottom: 1px solid rgba(white, 0.3);
            // }
        }
        // @include media-breakpoint-down (md) {
        //     top: unset;
        //     height: auto;
        //     width: unset;
        //     margin-right: -15px;
        //     margin-left: -15px;
        //     position: relative;
        //     &_next {
        //         margin-top: 20px !important;
        //     }
        //     &_container {
        //         padding: 30px 10px;
        //     }
        //     .wave {
        //         display: none;
        //     }
        //     &_body {
        //         padding: 50px 0 !important;
        //     }
        // }
        // @include media-breakpoint-down (sm) {
        //     &_container {
        //     }
        // }
        // @include media-breakpoint-down (lg) {
        //     &_body {
                
        //     }
        // }
        &_next {
            margin-top: 300px;
        }
        &_wrapper {
            line-height: 1;
            overflow: hidden;
            position: relative;
            height: 100%; width: 100%;
        }
        &_container {
          margin-top: -10px;
            z-index: 1;
        }
        &_body {
            color: white;
            text-align: center;
            padding: 20px 0;
            .title {
                font-weight: 800;
                @extend .text-size_165;
            }
            
            .text {
                font-weight: 400;
                @extend .text-size_20;

            }
        }
        &_body2 {
          color: white;
          text-align: center;
          padding: 20px 0;
          .title {
              font-weight: 800;
              @extend .text-size_165;
          }
          
          .text {
              font-weight: 400;
              @extend .text-size_20;

          }
      }
        &_waves {
            z-index: -1;
            height: 100%; width: 100%;
            &, .wave {
                top: 0; left: 0;
                position: absolute;
            }
            .wave {
                width: 200%;
                height: 100%;
                background: url("/images/wave 1.svg") repeat-x;
            }
            .wave1 {
                top: -10px;
                animation: wave-anim 10s linear infinite ;
            }
            .wave2 {
                top: 50px;
                opacity: 0.7;
                animation: wave-anim 20s linear infinite ;
            }
            .wave3 {
                opacity: 0.5;
                top: 100px;
                animation: wave-anim 40s linear infinite ;
            }
            .wave4 {
                opacity: 0.4;
                top: 130px;
                animation: wave-anim 5s linear infinite ;
            }
        }
    }

    &_solution {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &_item {
            $ctx: &;
            display: flex;
            align-items: center;
            flex-direction: column;
            .center-icon {
                @extend .transition;
                background-color: $light-color;
            }
            &:hover {
                .center-icon {
                    background-color: $main-color;
                }
                #{$ctx}_action {
                    opacity: 1;
                }
            }
            &_action {
                opacity: 0;
                @extend .transition;
            }
            
        }
        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 70px;
            &_item {
                .center-icon {
                    width: 70px; height: 70px;
                }
                &_action {
                    width: 20px !important; 
                    height: 20px !important;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            gap: 30px;
        }
    }

    &_map {
        width: 100%;
        height: 100%;
        min-height: 400px;
    }

    &_portfolio {
        width: 100%;
        gap: 0 30px;
        display: flex;
        overflow-x: auto;
        align-items: center;
        padding-bottom: 30px;
        justify-content: space-between;
        @include make-scrollbar(10px, 10px, transparent, #000, 0px);
        &_item {
            $ctx: &;
            width: 1000px;
            max-width: 90vw;
            flex: 0 0 auto;
            overflow: hidden;
            position: relative;
            img {
                width: 95%;
                height: 100%;
                padding-right: 30px;
                object-fit: cover;
            }
            &_title {
                top: 45%;
                left: 90%;
                height: 15%;
                width: 40%;
                opacity: 0.6; 
                text-align: center;
                position: absolute;
                // white-space: nowrap;
                @extend .transition;
                transform: rotate(90deg) translate(0%, 100px) ;
            }
            &_hover {
                color: white;
                text-align: left;
                position: absolute;
                top: 50%; left: 00%;
                width: calc(100% - 30px);
                @extend .transition;
                background: #E66D64;
                transform: translate(-100%, -50%);
                & > * {
                    padding: 50px 100px;
                }
            }
            &_button {
                width: 50px;
                height: 120%;
                position: absolute;
                right: 0%; top: 50%;
                background: $main-color;
                transform: translate(-0%, -50%);
            }
            &:hover {
                #{$ctx}_hover {
                    transform: translate(0%, -50%);
                }
                #{$ctx}_title {
                    opacity: 1;
                }
            }
        }
    }
}
.principal{
  margin-top: -40px;
}
.contact {
    $ctx: &;
    &_person-img {
        width: 195px;
        height: 195px;
    }
    &_person-info {
        margin: auto;
    }
    &_form {
        width: 100%;
        margin: auto;
        max-width: 900px;
    }
    &_banner {
        min-height: 300px;
        @extend .image-cover;
    }
    @include media-breakpoint-down (md) {
        &_person-img {
            width: 160px;
            height: 160px;
        }
    }
    @include media-breakpoint-down (sm) {
        &_person-info {
            width: fit-content;
        }
    }
}

.call-to-action {
    @include media-breakpoint-down (md) {
        text-align: center;
        justify-content: center;
    }
}


// iconos flotantes

.floating-icons {
    position: fixed;
    right: 10px; /* Ajusta la distancia desde el borde derecho según tus necesidades */
    top: 50%; /* Ajusta la posición vertical según tus necesidades */
    transform: translateY(-50%);
    z-index: 9999; /* Asegúrate de que los iconos estén en un z-index superior para que estén por encima del contenido */
}

.floating-icons a {
    display: block;
    background-color: #d3d3d3;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 5px; /* Ajusta el espacio entre los iconos según tus necesidades */
}

.floating-icons img {
    width: 30px; /* Ajusta el tamaño de los iconos según tus necesidades */
    height: 30px;
}

.floating-icons .whats:hover {
    background-color:#6FB253 !important;
}

.floating-icons a:hover {
    background-color:#C00020;
}


.timeline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.year {
  position: relative;
  padding: 7px 20px;
  border: 1px solid gray;
  border-radius: 5px;
}

.year:hover {
    background-color: #c91634;
    color: white;
   
  }
  
.year-content {
    position: absolute;
    bottom: -230px;
    left: -120px; /* Ajusta el valor según sea necesario */
    width: 400px; /* Ajusta el ancho según sea necesario */
    height: 200px; /* Ajusta el ancho según sea necesario */
    padding: 15px;
    background-color: #C00020;
    border-radius: 15px;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease;
    z-index: 2; /* Asegura que el contenido esté por encima de los demás elementos */
  }

 
   
#visual {
    position: absolute;
    top: 85px;
    right: -145px; /* Ajusta el valor según sea necesario */
    width: 400px; /* Ajusta el ancho según sea necesario */
    height: 200px; /* Ajusta el ancho según sea necesario */
    padding: 15px;
    background-color: #C00020;
    border-radius: 15px;
    text-align: left;
    z-index: 2; /* Asegura que el contenido esté por encima de los demás elementos */
    display: block;
    opacity: 1;
  }
  
  .timeline:hover #visual {
    opacity: 0;

  }

  .year:hover .year-content {
    visibility: visible;
    opacity: 1;
  }
  .year-content .content {
    margin-top: 5px;
  }
  
  .year-content::before {
    content: attr(data-title);
    display: block;
    font-weight: bold;
    font-size: 18px;
  }
  
  .year .close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
    visibility: hidden;
  }
  
  .year-content:hover .close {
    visibility: visible;
    color: rgb(255, 255, 255);

  }
  
  .year .close:hover {
    color: rgb(255, 255, 255);
  }


  .hoome-valor .swiper {
    width: 100%;
    height: 200px;
  }
.hoome-valor .swiper-slide {
    text-align: center;
    font-size: 15px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
   
    width: 180px; /* Tamaño deseado para las imágenes */
  height: 50px; /* Tamaño deseado para las imágenes */
  overflow: hidden;
}



.swiper .swiper-button-next::after {
  display: none;
}

.swiper .swiper-button-next {
    background-image: url(/build/images/icons/right.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 40px !important;
    height: 40px !important;
  }
  
  
.swiper .swiper-button-prev {
    background-image: url(/build/images/icons/left.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 40px !important;
    height: 40px !important;
//     // left: -26px !important;
//     // z-index: 200 !important;
  }
  
.swiper .swiper-button-prev::after {
    display: none;
  }




  main 
  {
    height: 70vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    perspective: 2px;
  }
  
  section 
  {
    transform-style: preserve-3d;
    position: relative;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center; 
    color: #FFF;
  }

  .parallax::after 
  {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(-1px) scale(1.5);
    background-size: 100%;
    z-index: -1;
  }
  
  .bg::after 
  {
    background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(/images/nosotros2.png);
    background-size: cover;
  }
  
  .myCategory .swiper-slide {
    text-align: center;
      font-size: 15px;
      background: #fff;
      height: calc((100% - 30px) / 2) !important;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
}

  .accordion-title{
    font-size: 12px;
  }
  







.detalle .accordion .accordion-item {
    border-bottom: 1px solid #e5e5e5;
  }
.detalle   .accordion .accordion-item button[aria-expanded='true'] {
    border-bottom: 1px solid #000000;
  }
 .detalle  .accordion button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: #000000;
    font-size: 1.15rem;
    font-weight: 600;
    border: none;
    background: none;
    outline: none;
  }
 .detalle  .accordion button:hover,
  .detalle .accordion button:focus {
    cursor: pointer;
    color: #0c0e0e;
  }
  .detalle .accordion button:hover::after,
 .detalle  .accordion button:focus::after {
    cursor: pointer;
    color: #0c0e0e;
    border: 1px solid #0c0e0e;
  }
 .detalle  .accordion button .accordion-title {
    padding: 1em 1.5em 1em 0;
  }
  .detalle .accordion button .icon {
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 0;
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;
  }
  .detalle .accordion button .icon::before {
    display: block;
    position: absolute;
    content: '';
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
  }
  .detalle .accordion button .icon::after {
    display: block;
    position: absolute;
    content: '';
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
  }
  .detalle .accordion button[aria-expanded='true'] {
    color: #0c0e0e;
  }
  .detalle .accordion button[aria-expanded='true'] .icon::after {
    width: 0;
  }
  .detalle .accordion button[aria-expanded='true'] + .accordion-content {
    opacity: 1;
    max-height: 12em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
  .detalle .accordion .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
  }
  .detalle .accordion .accordion-content p {
    font-size: 12px;
    font-weight: 300;
    margin: 2em 0;
    text-align:justify;
  }

  .detalle .accordion .accordion-content li {
    font-size: 12px;
    font-weight: 300;
  }
  .detalle .accordion-item.active .accordion-content {
    height: 1000px !important; /* Cambia el valor a uno mayor o ajusta según sea necesario */
}


.detalle-p .accordion-content {
  height: 1500px !important;
  // overflow: hidden;
  font-size: 12px;
  // transition: max-height 0.3s ease;
  margin-top: -20px;
  padding-left: 37px;
  padding-right: 20px;
}
.detalle-p .accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}
.detalle-p   .accordion .accordion-item button[aria-expanded='true'] {
  border-bottom: 1px solid #000000;
}
.detalle-p  .accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #000000;
  font-size: 1.15rem;
  font-weight: 600;
  border: none;
  background: none;
  outline: none;
}
.detalle-p  .accordion button:hover,
  .detalle-p .accordion button:focus {
    cursor: pointer;
    color: #0c0e0e;
  }
  .detalle-p .accordion button:hover::after,
 .detalle-p  .accordion button:focus::after {
    cursor: pointer;
    color: #0c0e0e;
    border: 1px solid #0c0e0e;
  }
 .detalle-p  .accordion button .accordion-title {
    padding: 1em 1.5em 1em 0;
  }
  .detalle-p .accordion button .icon {
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 0;
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;
  }
  .detalle-p .accordion button .icon::before {
    display: block;
    position: absolute;
    content: '';
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
  }
  .detalle-p .accordion button .icon::after {
    display: block;
    position: absolute;
    content: '';
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
  }
  .detalle-p .accordion button[aria-expanded='true'] {
    color: #0c0e0e;
  }
  .detalle-p .accordion button[aria-expanded='true'] .icon::after {
    width: 0;
  }
  .detalle-p .accordion button[aria-expanded='true'] + .accordion-content {
    opacity: 1;
    max-height: 40em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
  }
  .detalle-p .accordion .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
  }
  .detalle-p .accordion .accordion-content p {
    font-size: 12px;
    font-weight: 300;
    margin: 2em 0;
    text-align:justify;
  }
  .detalle-p .accordion .accordion-content li {
    font-size: 12px;
    font-weight: 300;
  }
  .detalle-p .accordion-item.active .accordion-content {
    height: 1500px !important; /* Cambia el valor a uno mayor o ajusta según sea necesario */
}

.flex-container {
  display: flex;
  align-items: stretch; /* Alinea ambos lados para que tengan la misma altura */
}

  .circles {
    width: 125px;
    height: 125px;
    margin: 0 auto;
    background-color: #dbdcdda5; /* Color de fondo del círculo */
    border-radius: 50%; /* Valor 50% para crear un círculo */
    display: flex;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  position: relative;
  overflow: hidden;
  }
.ocultar{
    // display: none;
    opacity: 0;
}
  .circles2 {
      background-color: #DC3545; /* Color de fondo del círculo */
      border-radius: 80%; /* Valor 50% para crear un círculo */
      color: white;
      padding: 10px 10px;
    }  
  .circles:hover {
    background-color: #DC3545; /* Cambiamos el color de fondo al pasar el mouse */
  }
  
  .circles:hover ~ .ocultar {
    transition: opacity 0.3s ease; /* Transición de opacidad */
    opacity: 1; /* Al pasar el mouse, el ícono se vuelve visible */
  }

#player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: transform 0.5s;
    transform: scale(2.5); /* Aplicar zoom */
}


.accordion-buttons {
  width: 100%;
  text-align: left;
  // padding: 10px;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
align-items: center;
}

.accordion-buttons img {
  margin-right: 10px;
}

.accordion-contents {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  margin-top: -20px;
padding-top: 5px;
padding-left: 47px;
padding-right: 20px;
}

.accordion-items.active .accordion-contents {
  max-height: 500px; /* Establece la altura máxima deseada */
}


.accordion-buttons .minus-icons {
  display: none;
}

.accordion-buttons[aria-expanded="true"] .minus-icons {
  display: inline;
}

.accordion-buttons[aria-expanded="true"] .plus-icons {
  display: none;
}

.quien{
  position: absolute;
  margin-left: 51%;
  margin-top: 3%;
  font-weight: 600;
  font-size: 33px;
}

.content-g{
  width: 50%!important;
  font-size: 18px !important;
}
// .myCategory strong{
//   font-size: 18px !important;
// }
// #mision{
//   display: none;
// }

.content-3{
  width: 49%!important;
}