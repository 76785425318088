



























.z-icon {
	display: inline-flex;
	align-self: center;
	justify-content: center;

	svg, img {
		height: 1em;
		width: 1em;
		fill: currentColor;
		top: .25em;
		position: relative;
	}
}
