$main-color: #CE072B;

$light-color: #F1ECEB;
$dark-color: #2C2A26;
$shade-color: #B6B6B6;

$blue-darker: #162140;
$blue-lighter: #175B81;

$whatsapp-color: #51B444;

$text-colors-array: (
    'white': white,
    'black': black,
    'main': $main-color,
    'light': $light-color,
    'dark': $dark-color,
    'shade': $shade-color,
    'blue-darker': $blue-darker,
    'blue-lighter': $blue-lighter,
    'whatsapp': $whatsapp-color,
);
    
$background-colors-array: (
    'white': white,
    'black': black,
    'main': $main-color,
    'light': $light-color,
    'dark': $dark-color,
    'shade': $shade-color,
    'blue-darker': $blue-darker,
    'blue-lighter': $blue-lighter,
    'whatsapp': $whatsapp-color,
);

$mobile-breakpoint: 928px;

// max, min
$text-sizes-array: (
    '12': (
        12px, 11px,
    ),
    '14': (
        14px, 12px,
    ),
    '16': (
        16px, 12px,
    ),
    '17': (
        17px, 13px,
    ),
    '18': (
        18px, 13px,
    ),
    '20': (
        20px, 13px,
    ),
    '25': (
        25px, 14px,
    ),
    '28': (
        28px, 13px,
    ),
    '30': (
        30px, 17px,
    ),
    '35': (
        35px, 21px,
    ),
    '40': (
        40px, 30px,
    ),
    '50': (
        50px, 30px,
    ),
    '60': (
        60px, 30px,
    ),
    '100': (
        100px, 40px,
    ),
    '165': (
        165px, 120px,
    ),
    'sm-icon': (
        13px, 13px
    ),
    'md-icon': (
        20px, 15px
    ),
    'lg-icon': (
        30px, 20px
    ),
    'xl-icon': (
        40px, 30px
    ),
    'xxl-icon': (
        60px, 40px
    ),
);