//General fixes
a,
a:hover {
    text-decoration: none;
    color: inherit;
}

@function url-svg($icon) {
    @return url("data:image/svg+xml;utf8,#{str-replace($icon, "#", "%23")}");
}

//Size helpers
.full-height {
    height: 100vh;
    &-sm {
        @include media-breakpoint-up(sm) {
            height: 100vh;
        }
    }
    &-md {
        @include media-breakpoint-up(md) {
            height: 100vh;
        }
    }
    &-lg {
        @include media-breakpoint-up(lg) {
            height: 100vh;
        }
    }
    &-xl {
        @include media-breakpoint-up(xl) {
            height: 100vh;
        }
    }
}

.s-full {
    width: 100%;
    height: 100%;
}

.t-0 {
    top: 0;
}

.b-0 {
    bottom: 0;
}

.r-0 {
    right: 0;
}

.l-0 {
    left: 0;
}

//Others
.bg-image {
    background: center center no-repeat;
    background-size: cover;
}

.cursor-pointer {
    cursor: pointer;
}

.transition3s {
    transition: 0.3s ease all;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

.image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.i-list {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
    position: relative;
    &_icon {
        left: -2em;
        position: absolute;
        text-align: center;
        width: 2em;
        line-height: inherit;
    }
}

.absolute-fully-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.absolute-vertically-centered {
    position: absolute;
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
}

.absolute-vertically-bottom {
    position: absolute;
    top: unset;
    bottom: 0%;
    transform: translateY(-50%);
}

.absolute-vertically-top {
    position: absolute;
    top: 0%;
    bottom: unset;
    transform: translateY(-50%);
}

@mixin title-responsive($size, $min-size: 30px, $interline-rate: 1) {
    $xxl: $size * 1;
    $xl: $size * 0.7;
    $lg: $size * 0.6;
    $md: $size * 0.5;
    $sm: $size * 0.4;
    $xs: $size * 0.2;

    font-size: $size;
    @include media-breakpoint-down(xxl) {
        font-size: if($xxl > $min-size, $xxl, $min-size) !important;
        line-height: if(
            $xxl > 16px,
            $xxl * $interline-rate,
            $min-size * $interline-rate
        );
    }
    @include media-breakpoint-down(xl) {
        font-size: if($xl > $min-size, $xl, $min-size) !important;
        line-height: if(
            $xl > 16px,
            $xl * $interline-rate,
            $min-size * $interline-rate
        );
    }
    @include media-breakpoint-down(lg) {
        font-size: if($lg > $min-size, $lg, $min-size) !important;
        line-height: if(
            $lg > 16px,
            $lg * $interline-rate,
            $min-size * $interline-rate
        );
    }
    @include media-breakpoint-down(md) {
        font-size: if($md > $min-size, $md, $min-size) !important;
        line-height: if(
            $md > 16px,
            $md * $interline-rate,
            $min-size * $interline-rate
        );
    }
    @include media-breakpoint-down(sm) {
        font-size: if($sm > $min-size, $sm, $min-size) !important;
        line-height: if(
            $sm > 16px,
            $sm * $interline-rate,
            $min-size * $interline-rate
        );
    }
    @include media-breakpoint-down(xs) {
        font-size: if($xs > $min-size, $min-size, $min-size) !important;
        line-height: if(
            $xs > 16px,
            $xs * $interline-rate,
            $min-size * $interline-rate
        );
    }
}

@mixin text-responsive($size, $min-size: 23pt, $name: "default") {
    $xxl: $size * 1;
    $xl: $size * 0.7;
    $lg: $size * 0.6;
    $md: $size * 0.5;
    $sm: $size * 0.4;
    $xs: $size * 0.2;

    font-size: $size;
    @include media-breakpoint-down(xxl) {
        font-size: if($xxl > $min-size, $xxl, $min-size) !important;
    }
    @include media-breakpoint-down(xl) {
        font-size: if($xl > $min-size, $xl, $min-size) !important;
    }
    @include media-breakpoint-down(lg) {
        font-size: if($lg > $min-size, $lg, $min-size) !important;
    }
    @include media-breakpoint-down(md) {
        font-size: if($md > $min-size, $md, $min-size) !important;
    }
    @include media-breakpoint-down(sm) {
        font-size: if($sm > $min-size, $sm, $min-size) !important;
    }
    @include media-breakpoint-down(xs) {
        font-size: if($xs > $min-size, $min-size, $min-size) !important;
    }
}

@mixin make-scrollbar(
    $width: 10px,
    $height: 10px,
    $bgTrack: #f1f1f1,
    $bgThumb: #888,
    $borderRadius: 0,
    $bgHover: #8f8f8f
) {
    /* width */
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $bgTrack;
        border-radius: $borderRadius;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        transition: 0.3s ease-in-out;
        background: $bgThumb;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $bgHover;
    }
}
