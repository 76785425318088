@charset "UTF-8";
@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Medium.eot");
  src: url("Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("Montserrat-Medium.woff2") format("woff2"), url("Montserrat-Medium.woff") format("woff"), url("Montserrat-Medium.ttf") format("truetype"), url("Montserrat-Medium.svg#Montserrat-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Bold.eot");
  src: url("Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("Montserrat-Bold.woff2") format("woff2"), url("Montserrat-Bold.woff") format("woff"), url("Montserrat-Bold.ttf") format("truetype"), url("Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Light.eot");
  src: url("Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("Montserrat-Light.woff2") format("woff2"), url("Montserrat-Light.woff") format("woff"), url("Montserrat-Light.ttf") format("truetype"), url("Montserrat-Light.svg#Montserrat-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-SemiBold.eot");
  src: url("Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("Montserrat-SemiBold.woff2") format("woff2"), url("Montserrat-SemiBold.woff") format("woff"), url("Montserrat-SemiBold.ttf") format("truetype"), url("Montserrat-SemiBold.svg#Montserrat-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url("Montserrat-Regular.eot");
  src: url("Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("Montserrat-Regular.woff2") format("woff2"), url("Montserrat-Regular.woff") format("woff"), url("Montserrat-Regular.ttf") format("truetype"), url("Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1500px) {
  .d-mxl-none {
    display: none !important;
  }
  .d-mxl-inline {
    display: inline !important;
  }
  .d-mxl-inline-block {
    display: inline-block !important;
  }
  .d-mxl-block {
    display: block !important;
  }
  .d-mxl-table {
    display: table !important;
  }
  .d-mxl-table-row {
    display: table-row !important;
  }
  .d-mxl-table-cell {
    display: table-cell !important;
  }
  .d-mxl-flex {
    display: flex !important;
  }
  .d-mxl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1921px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1500px) {
  .flex-mxl-row {
    flex-direction: row !important;
  }
  .flex-mxl-column {
    flex-direction: column !important;
  }
  .flex-mxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-mxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-mxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-mxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-mxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-mxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-mxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-mxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-mxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-mxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-mxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-mxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-mxl-center {
    justify-content: center !important;
  }
  .justify-content-mxl-between {
    justify-content: space-between !important;
  }
  .justify-content-mxl-around {
    justify-content: space-around !important;
  }
  .align-items-mxl-start {
    align-items: flex-start !important;
  }
  .align-items-mxl-end {
    align-items: flex-end !important;
  }
  .align-items-mxl-center {
    align-items: center !important;
  }
  .align-items-mxl-baseline {
    align-items: baseline !important;
  }
  .align-items-mxl-stretch {
    align-items: stretch !important;
  }
  .align-content-mxl-start {
    align-content: flex-start !important;
  }
  .align-content-mxl-end {
    align-content: flex-end !important;
  }
  .align-content-mxl-center {
    align-content: center !important;
  }
  .align-content-mxl-between {
    align-content: space-between !important;
  }
  .align-content-mxl-around {
    align-content: space-around !important;
  }
  .align-content-mxl-stretch {
    align-content: stretch !important;
  }
  .align-self-mxl-auto {
    align-self: auto !important;
  }
  .align-self-mxl-start {
    align-self: flex-start !important;
  }
  .align-self-mxl-end {
    align-self: flex-end !important;
  }
  .align-self-mxl-center {
    align-self: center !important;
  }
  .align-self-mxl-baseline {
    align-self: baseline !important;
  }
  .align-self-mxl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1921px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1500px) {
  .float-mxl-left {
    float: left !important;
  }
  .float-mxl-right {
    float: right !important;
  }
  .float-mxl-none {
    float: none !important;
  }
}

@media (min-width: 1921px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 2rem !important;
}

.mt-3,
.my-3 {
  margin-top: 2rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 2rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 2rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 2rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.mt-4,
.my-4 {
  margin-top: 3rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 3rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 3rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 3rem !important;
}

.m-5 {
  margin: 6rem !important;
}

.mt-5,
.my-5 {
  margin-top: 6rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 6rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 6rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 6rem !important;
}

.m-6 {
  margin: 9rem !important;
}

.mt-6,
.my-6 {
  margin-top: 9rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 9rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 9rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 9rem !important;
}

.m-7 {
  margin: 12rem !important;
}

.mt-7,
.my-7 {
  margin-top: 12rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 12rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 12rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 12rem !important;
}

.m-8 {
  margin: 15rem !important;
}

.mt-8,
.my-8 {
  margin-top: 15rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 15rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 15rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 15rem !important;
}

.m-9 {
  margin: 18rem !important;
}

.mt-9,
.my-9 {
  margin-top: 18rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 18rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 18rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 18rem !important;
}

.m-10 {
  margin: 21rem !important;
}

.mt-10,
.my-10 {
  margin-top: 21rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 21rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 21rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 21rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 2rem !important;
}

.pt-3,
.py-3 {
  padding-top: 2rem !important;
}

.pr-3,
.px-3 {
  padding-right: 2rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 2rem !important;
}

.pl-3,
.px-3 {
  padding-left: 2rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.pt-4,
.py-4 {
  padding-top: 3rem !important;
}

.pr-4,
.px-4 {
  padding-right: 3rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 3rem !important;
}

.pl-4,
.px-4 {
  padding-left: 3rem !important;
}

.p-5 {
  padding: 6rem !important;
}

.pt-5,
.py-5 {
  padding-top: 6rem !important;
}

.pr-5,
.px-5 {
  padding-right: 6rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 6rem !important;
}

.pl-5,
.px-5 {
  padding-left: 6rem !important;
}

.p-6 {
  padding: 9rem !important;
}

.pt-6,
.py-6 {
  padding-top: 9rem !important;
}

.pr-6,
.px-6 {
  padding-right: 9rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 9rem !important;
}

.pl-6,
.px-6 {
  padding-left: 9rem !important;
}

.p-7 {
  padding: 12rem !important;
}

.pt-7,
.py-7 {
  padding-top: 12rem !important;
}

.pr-7,
.px-7 {
  padding-right: 12rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 12rem !important;
}

.pl-7,
.px-7 {
  padding-left: 12rem !important;
}

.p-8 {
  padding: 15rem !important;
}

.pt-8,
.py-8 {
  padding-top: 15rem !important;
}

.pr-8,
.px-8 {
  padding-right: 15rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 15rem !important;
}

.pl-8,
.px-8 {
  padding-left: 15rem !important;
}

.p-9 {
  padding: 18rem !important;
}

.pt-9,
.py-9 {
  padding-top: 18rem !important;
}

.pr-9,
.px-9 {
  padding-right: 18rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 18rem !important;
}

.pl-9,
.px-9 {
  padding-left: 18rem !important;
}

.p-10 {
  padding: 21rem !important;
}

.pt-10,
.py-10 {
  padding-top: 21rem !important;
}

.pr-10,
.px-10 {
  padding-right: 21rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 21rem !important;
}

.pl-10,
.px-10 {
  padding-left: 21rem !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important;
}

.m-n3 {
  margin: -2rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -2rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -2rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -2rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -2rem !important;
}

.m-n4 {
  margin: -3rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -3rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -3rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -3rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -3rem !important;
}

.m-n5 {
  margin: -6rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -6rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -6rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -6rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -6rem !important;
}

.m-n6 {
  margin: -9rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -9rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -9rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -9rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -9rem !important;
}

.m-n7 {
  margin: -12rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -12rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -12rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -12rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -12rem !important;
}

.m-n8 {
  margin: -15rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -15rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -15rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -15rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -15rem !important;
}

.m-n9 {
  margin: -18rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -18rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -18rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -18rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -18rem !important;
}

.m-n10 {
  margin: -21rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -21rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -21rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -21rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -21rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .m-sm-3 {
    margin: 2rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important;
  }
  .m-sm-4 {
    margin: 3rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important;
  }
  .m-sm-5 {
    margin: 6rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 6rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 6rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 6rem !important;
  }
  .m-sm-6 {
    margin: 9rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 9rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 9rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 9rem !important;
  }
  .m-sm-7 {
    margin: 12rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 12rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 12rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 12rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 12rem !important;
  }
  .m-sm-8 {
    margin: 15rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 15rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 15rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 15rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 15rem !important;
  }
  .m-sm-9 {
    margin: 18rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 18rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 18rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 18rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 18rem !important;
  }
  .m-sm-10 {
    margin: 21rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 21rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 21rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 21rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 21rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important;
  }
  .p-sm-3 {
    padding: 2rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important;
  }
  .p-sm-4 {
    padding: 3rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important;
  }
  .p-sm-5 {
    padding: 6rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 6rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 6rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 6rem !important;
  }
  .p-sm-6 {
    padding: 9rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 9rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 9rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 9rem !important;
  }
  .p-sm-7 {
    padding: 12rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 12rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 12rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 12rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 12rem !important;
  }
  .p-sm-8 {
    padding: 15rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 15rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 15rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 15rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 15rem !important;
  }
  .p-sm-9 {
    padding: 18rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 18rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 18rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 18rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 18rem !important;
  }
  .p-sm-10 {
    padding: 21rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 21rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 21rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 21rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 21rem !important;
  }
  .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n2 {
    margin: -1rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1rem !important;
  }
  .m-sm-n3 {
    margin: -2rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -2rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -2rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -2rem !important;
  }
  .m-sm-n4 {
    margin: -3rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -3rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -3rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -3rem !important;
  }
  .m-sm-n5 {
    margin: -6rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -6rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -6rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -6rem !important;
  }
  .m-sm-n6 {
    margin: -9rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -9rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -9rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -9rem !important;
  }
  .m-sm-n7 {
    margin: -12rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -12rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -12rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -12rem !important;
  }
  .m-sm-n8 {
    margin: -15rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -15rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -15rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -15rem !important;
  }
  .m-sm-n9 {
    margin: -18rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -18rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -18rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -18rem !important;
  }
  .m-sm-n10 {
    margin: -21rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -21rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -21rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -21rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important;
  }
  .m-md-3 {
    margin: 2rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important;
  }
  .m-md-4 {
    margin: 3rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important;
  }
  .m-md-5 {
    margin: 6rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 6rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 6rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 6rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 6rem !important;
  }
  .m-md-6 {
    margin: 9rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 9rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 9rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 9rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 9rem !important;
  }
  .m-md-7 {
    margin: 12rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 12rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 12rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 12rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 12rem !important;
  }
  .m-md-8 {
    margin: 15rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 15rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 15rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 15rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 15rem !important;
  }
  .m-md-9 {
    margin: 18rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 18rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 18rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 18rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 18rem !important;
  }
  .m-md-10 {
    margin: 21rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 21rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 21rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 21rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 21rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important;
  }
  .p-md-3 {
    padding: 2rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important;
  }
  .p-md-4 {
    padding: 3rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important;
  }
  .p-md-5 {
    padding: 6rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 6rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 6rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 6rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 6rem !important;
  }
  .p-md-6 {
    padding: 9rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 9rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 9rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 9rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 9rem !important;
  }
  .p-md-7 {
    padding: 12rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 12rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 12rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 12rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 12rem !important;
  }
  .p-md-8 {
    padding: 15rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 15rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 15rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 15rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 15rem !important;
  }
  .p-md-9 {
    padding: 18rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 18rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 18rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 18rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 18rem !important;
  }
  .p-md-10 {
    padding: 21rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 21rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 21rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 21rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 21rem !important;
  }
  .m-md-n1 {
    margin: -0.5rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important;
  }
  .m-md-n2 {
    margin: -1rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important;
  }
  .m-md-n3 {
    margin: -2rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -2rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -2rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -2rem !important;
  }
  .m-md-n4 {
    margin: -3rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -3rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -3rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -3rem !important;
  }
  .m-md-n5 {
    margin: -6rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -6rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -6rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -6rem !important;
  }
  .m-md-n6 {
    margin: -9rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -9rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -9rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -9rem !important;
  }
  .m-md-n7 {
    margin: -12rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -12rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -12rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -12rem !important;
  }
  .m-md-n8 {
    margin: -15rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -15rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -15rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -15rem !important;
  }
  .m-md-n9 {
    margin: -18rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -18rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -18rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -18rem !important;
  }
  .m-md-n10 {
    margin: -21rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -21rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -21rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -21rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .m-lg-3 {
    margin: 2rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important;
  }
  .m-lg-4 {
    margin: 3rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important;
  }
  .m-lg-5 {
    margin: 6rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 6rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 6rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 6rem !important;
  }
  .m-lg-6 {
    margin: 9rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 9rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 9rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 9rem !important;
  }
  .m-lg-7 {
    margin: 12rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 12rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 12rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 12rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 12rem !important;
  }
  .m-lg-8 {
    margin: 15rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 15rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 15rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 15rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 15rem !important;
  }
  .m-lg-9 {
    margin: 18rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 18rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 18rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 18rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 18rem !important;
  }
  .m-lg-10 {
    margin: 21rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 21rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 21rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 21rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 21rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important;
  }
  .p-lg-3 {
    padding: 2rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important;
  }
  .p-lg-4 {
    padding: 3rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important;
  }
  .p-lg-5 {
    padding: 6rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 6rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 6rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 6rem !important;
  }
  .p-lg-6 {
    padding: 9rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 9rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 9rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 9rem !important;
  }
  .p-lg-7 {
    padding: 12rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 12rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 12rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 12rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 12rem !important;
  }
  .p-lg-8 {
    padding: 15rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 15rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 15rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 15rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 15rem !important;
  }
  .p-lg-9 {
    padding: 18rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 18rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 18rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 18rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 18rem !important;
  }
  .p-lg-10 {
    padding: 21rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 21rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 21rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 21rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 21rem !important;
  }
  .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n2 {
    margin: -1rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important;
  }
  .m-lg-n3 {
    margin: -2rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -2rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -2rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -2rem !important;
  }
  .m-lg-n4 {
    margin: -3rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -3rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -3rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -3rem !important;
  }
  .m-lg-n5 {
    margin: -6rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -6rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -6rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -6rem !important;
  }
  .m-lg-n6 {
    margin: -9rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -9rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -9rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -9rem !important;
  }
  .m-lg-n7 {
    margin: -12rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -12rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -12rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -12rem !important;
  }
  .m-lg-n8 {
    margin: -15rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -15rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -15rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -15rem !important;
  }
  .m-lg-n9 {
    margin: -18rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -18rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -18rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -18rem !important;
  }
  .m-lg-n10 {
    margin: -21rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -21rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -21rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -21rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .m-xl-3 {
    margin: 2rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important;
  }
  .m-xl-4 {
    margin: 3rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important;
  }
  .m-xl-5 {
    margin: 6rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 6rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 6rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 6rem !important;
  }
  .m-xl-6 {
    margin: 9rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 9rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 9rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 9rem !important;
  }
  .m-xl-7 {
    margin: 12rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 12rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 12rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 12rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 12rem !important;
  }
  .m-xl-8 {
    margin: 15rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 15rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 15rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 15rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 15rem !important;
  }
  .m-xl-9 {
    margin: 18rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 18rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 18rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 18rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 18rem !important;
  }
  .m-xl-10 {
    margin: 21rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 21rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 21rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 21rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 21rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important;
  }
  .p-xl-3 {
    padding: 2rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important;
  }
  .p-xl-4 {
    padding: 3rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important;
  }
  .p-xl-5 {
    padding: 6rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 6rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 6rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 6rem !important;
  }
  .p-xl-6 {
    padding: 9rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 9rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 9rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 9rem !important;
  }
  .p-xl-7 {
    padding: 12rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 12rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 12rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 12rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 12rem !important;
  }
  .p-xl-8 {
    padding: 15rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 15rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 15rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 15rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 15rem !important;
  }
  .p-xl-9 {
    padding: 18rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 18rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 18rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 18rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 18rem !important;
  }
  .p-xl-10 {
    padding: 21rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 21rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 21rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 21rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 21rem !important;
  }
  .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n2 {
    margin: -1rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important;
  }
  .m-xl-n3 {
    margin: -2rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -2rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -2rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -2rem !important;
  }
  .m-xl-n4 {
    margin: -3rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -3rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -3rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -3rem !important;
  }
  .m-xl-n5 {
    margin: -6rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -6rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -6rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -6rem !important;
  }
  .m-xl-n6 {
    margin: -9rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -9rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -9rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -9rem !important;
  }
  .m-xl-n7 {
    margin: -12rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -12rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -12rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -12rem !important;
  }
  .m-xl-n8 {
    margin: -15rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -15rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -15rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -15rem !important;
  }
  .m-xl-n9 {
    margin: -18rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -18rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -18rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -18rem !important;
  }
  .m-xl-n10 {
    margin: -21rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -21rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -21rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -21rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1500px) {
  .m-mxl-0 {
    margin: 0 !important;
  }
  .mt-mxl-0,
  .my-mxl-0 {
    margin-top: 0 !important;
  }
  .mr-mxl-0,
  .mx-mxl-0 {
    margin-right: 0 !important;
  }
  .mb-mxl-0,
  .my-mxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-mxl-0,
  .mx-mxl-0 {
    margin-left: 0 !important;
  }
  .m-mxl-1 {
    margin: 0.5rem !important;
  }
  .mt-mxl-1,
  .my-mxl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-mxl-1,
  .mx-mxl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-mxl-1,
  .my-mxl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-mxl-1,
  .mx-mxl-1 {
    margin-left: 0.5rem !important;
  }
  .m-mxl-2 {
    margin: 1rem !important;
  }
  .mt-mxl-2,
  .my-mxl-2 {
    margin-top: 1rem !important;
  }
  .mr-mxl-2,
  .mx-mxl-2 {
    margin-right: 1rem !important;
  }
  .mb-mxl-2,
  .my-mxl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-mxl-2,
  .mx-mxl-2 {
    margin-left: 1rem !important;
  }
  .m-mxl-3 {
    margin: 2rem !important;
  }
  .mt-mxl-3,
  .my-mxl-3 {
    margin-top: 2rem !important;
  }
  .mr-mxl-3,
  .mx-mxl-3 {
    margin-right: 2rem !important;
  }
  .mb-mxl-3,
  .my-mxl-3 {
    margin-bottom: 2rem !important;
  }
  .ml-mxl-3,
  .mx-mxl-3 {
    margin-left: 2rem !important;
  }
  .m-mxl-4 {
    margin: 3rem !important;
  }
  .mt-mxl-4,
  .my-mxl-4 {
    margin-top: 3rem !important;
  }
  .mr-mxl-4,
  .mx-mxl-4 {
    margin-right: 3rem !important;
  }
  .mb-mxl-4,
  .my-mxl-4 {
    margin-bottom: 3rem !important;
  }
  .ml-mxl-4,
  .mx-mxl-4 {
    margin-left: 3rem !important;
  }
  .m-mxl-5 {
    margin: 6rem !important;
  }
  .mt-mxl-5,
  .my-mxl-5 {
    margin-top: 6rem !important;
  }
  .mr-mxl-5,
  .mx-mxl-5 {
    margin-right: 6rem !important;
  }
  .mb-mxl-5,
  .my-mxl-5 {
    margin-bottom: 6rem !important;
  }
  .ml-mxl-5,
  .mx-mxl-5 {
    margin-left: 6rem !important;
  }
  .m-mxl-6 {
    margin: 9rem !important;
  }
  .mt-mxl-6,
  .my-mxl-6 {
    margin-top: 9rem !important;
  }
  .mr-mxl-6,
  .mx-mxl-6 {
    margin-right: 9rem !important;
  }
  .mb-mxl-6,
  .my-mxl-6 {
    margin-bottom: 9rem !important;
  }
  .ml-mxl-6,
  .mx-mxl-6 {
    margin-left: 9rem !important;
  }
  .m-mxl-7 {
    margin: 12rem !important;
  }
  .mt-mxl-7,
  .my-mxl-7 {
    margin-top: 12rem !important;
  }
  .mr-mxl-7,
  .mx-mxl-7 {
    margin-right: 12rem !important;
  }
  .mb-mxl-7,
  .my-mxl-7 {
    margin-bottom: 12rem !important;
  }
  .ml-mxl-7,
  .mx-mxl-7 {
    margin-left: 12rem !important;
  }
  .m-mxl-8 {
    margin: 15rem !important;
  }
  .mt-mxl-8,
  .my-mxl-8 {
    margin-top: 15rem !important;
  }
  .mr-mxl-8,
  .mx-mxl-8 {
    margin-right: 15rem !important;
  }
  .mb-mxl-8,
  .my-mxl-8 {
    margin-bottom: 15rem !important;
  }
  .ml-mxl-8,
  .mx-mxl-8 {
    margin-left: 15rem !important;
  }
  .m-mxl-9 {
    margin: 18rem !important;
  }
  .mt-mxl-9,
  .my-mxl-9 {
    margin-top: 18rem !important;
  }
  .mr-mxl-9,
  .mx-mxl-9 {
    margin-right: 18rem !important;
  }
  .mb-mxl-9,
  .my-mxl-9 {
    margin-bottom: 18rem !important;
  }
  .ml-mxl-9,
  .mx-mxl-9 {
    margin-left: 18rem !important;
  }
  .m-mxl-10 {
    margin: 21rem !important;
  }
  .mt-mxl-10,
  .my-mxl-10 {
    margin-top: 21rem !important;
  }
  .mr-mxl-10,
  .mx-mxl-10 {
    margin-right: 21rem !important;
  }
  .mb-mxl-10,
  .my-mxl-10 {
    margin-bottom: 21rem !important;
  }
  .ml-mxl-10,
  .mx-mxl-10 {
    margin-left: 21rem !important;
  }
  .p-mxl-0 {
    padding: 0 !important;
  }
  .pt-mxl-0,
  .py-mxl-0 {
    padding-top: 0 !important;
  }
  .pr-mxl-0,
  .px-mxl-0 {
    padding-right: 0 !important;
  }
  .pb-mxl-0,
  .py-mxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-mxl-0,
  .px-mxl-0 {
    padding-left: 0 !important;
  }
  .p-mxl-1 {
    padding: 0.5rem !important;
  }
  .pt-mxl-1,
  .py-mxl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-mxl-1,
  .px-mxl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-mxl-1,
  .py-mxl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-mxl-1,
  .px-mxl-1 {
    padding-left: 0.5rem !important;
  }
  .p-mxl-2 {
    padding: 1rem !important;
  }
  .pt-mxl-2,
  .py-mxl-2 {
    padding-top: 1rem !important;
  }
  .pr-mxl-2,
  .px-mxl-2 {
    padding-right: 1rem !important;
  }
  .pb-mxl-2,
  .py-mxl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-mxl-2,
  .px-mxl-2 {
    padding-left: 1rem !important;
  }
  .p-mxl-3 {
    padding: 2rem !important;
  }
  .pt-mxl-3,
  .py-mxl-3 {
    padding-top: 2rem !important;
  }
  .pr-mxl-3,
  .px-mxl-3 {
    padding-right: 2rem !important;
  }
  .pb-mxl-3,
  .py-mxl-3 {
    padding-bottom: 2rem !important;
  }
  .pl-mxl-3,
  .px-mxl-3 {
    padding-left: 2rem !important;
  }
  .p-mxl-4 {
    padding: 3rem !important;
  }
  .pt-mxl-4,
  .py-mxl-4 {
    padding-top: 3rem !important;
  }
  .pr-mxl-4,
  .px-mxl-4 {
    padding-right: 3rem !important;
  }
  .pb-mxl-4,
  .py-mxl-4 {
    padding-bottom: 3rem !important;
  }
  .pl-mxl-4,
  .px-mxl-4 {
    padding-left: 3rem !important;
  }
  .p-mxl-5 {
    padding: 6rem !important;
  }
  .pt-mxl-5,
  .py-mxl-5 {
    padding-top: 6rem !important;
  }
  .pr-mxl-5,
  .px-mxl-5 {
    padding-right: 6rem !important;
  }
  .pb-mxl-5,
  .py-mxl-5 {
    padding-bottom: 6rem !important;
  }
  .pl-mxl-5,
  .px-mxl-5 {
    padding-left: 6rem !important;
  }
  .p-mxl-6 {
    padding: 9rem !important;
  }
  .pt-mxl-6,
  .py-mxl-6 {
    padding-top: 9rem !important;
  }
  .pr-mxl-6,
  .px-mxl-6 {
    padding-right: 9rem !important;
  }
  .pb-mxl-6,
  .py-mxl-6 {
    padding-bottom: 9rem !important;
  }
  .pl-mxl-6,
  .px-mxl-6 {
    padding-left: 9rem !important;
  }
  .p-mxl-7 {
    padding: 12rem !important;
  }
  .pt-mxl-7,
  .py-mxl-7 {
    padding-top: 12rem !important;
  }
  .pr-mxl-7,
  .px-mxl-7 {
    padding-right: 12rem !important;
  }
  .pb-mxl-7,
  .py-mxl-7 {
    padding-bottom: 12rem !important;
  }
  .pl-mxl-7,
  .px-mxl-7 {
    padding-left: 12rem !important;
  }
  .p-mxl-8 {
    padding: 15rem !important;
  }
  .pt-mxl-8,
  .py-mxl-8 {
    padding-top: 15rem !important;
  }
  .pr-mxl-8,
  .px-mxl-8 {
    padding-right: 15rem !important;
  }
  .pb-mxl-8,
  .py-mxl-8 {
    padding-bottom: 15rem !important;
  }
  .pl-mxl-8,
  .px-mxl-8 {
    padding-left: 15rem !important;
  }
  .p-mxl-9 {
    padding: 18rem !important;
  }
  .pt-mxl-9,
  .py-mxl-9 {
    padding-top: 18rem !important;
  }
  .pr-mxl-9,
  .px-mxl-9 {
    padding-right: 18rem !important;
  }
  .pb-mxl-9,
  .py-mxl-9 {
    padding-bottom: 18rem !important;
  }
  .pl-mxl-9,
  .px-mxl-9 {
    padding-left: 18rem !important;
  }
  .p-mxl-10 {
    padding: 21rem !important;
  }
  .pt-mxl-10,
  .py-mxl-10 {
    padding-top: 21rem !important;
  }
  .pr-mxl-10,
  .px-mxl-10 {
    padding-right: 21rem !important;
  }
  .pb-mxl-10,
  .py-mxl-10 {
    padding-bottom: 21rem !important;
  }
  .pl-mxl-10,
  .px-mxl-10 {
    padding-left: 21rem !important;
  }
  .m-mxl-n1 {
    margin: -0.5rem !important;
  }
  .mt-mxl-n1,
  .my-mxl-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-mxl-n1,
  .mx-mxl-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-mxl-n1,
  .my-mxl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-mxl-n1,
  .mx-mxl-n1 {
    margin-left: -0.5rem !important;
  }
  .m-mxl-n2 {
    margin: -1rem !important;
  }
  .mt-mxl-n2,
  .my-mxl-n2 {
    margin-top: -1rem !important;
  }
  .mr-mxl-n2,
  .mx-mxl-n2 {
    margin-right: -1rem !important;
  }
  .mb-mxl-n2,
  .my-mxl-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-mxl-n2,
  .mx-mxl-n2 {
    margin-left: -1rem !important;
  }
  .m-mxl-n3 {
    margin: -2rem !important;
  }
  .mt-mxl-n3,
  .my-mxl-n3 {
    margin-top: -2rem !important;
  }
  .mr-mxl-n3,
  .mx-mxl-n3 {
    margin-right: -2rem !important;
  }
  .mb-mxl-n3,
  .my-mxl-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-mxl-n3,
  .mx-mxl-n3 {
    margin-left: -2rem !important;
  }
  .m-mxl-n4 {
    margin: -3rem !important;
  }
  .mt-mxl-n4,
  .my-mxl-n4 {
    margin-top: -3rem !important;
  }
  .mr-mxl-n4,
  .mx-mxl-n4 {
    margin-right: -3rem !important;
  }
  .mb-mxl-n4,
  .my-mxl-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-mxl-n4,
  .mx-mxl-n4 {
    margin-left: -3rem !important;
  }
  .m-mxl-n5 {
    margin: -6rem !important;
  }
  .mt-mxl-n5,
  .my-mxl-n5 {
    margin-top: -6rem !important;
  }
  .mr-mxl-n5,
  .mx-mxl-n5 {
    margin-right: -6rem !important;
  }
  .mb-mxl-n5,
  .my-mxl-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-mxl-n5,
  .mx-mxl-n5 {
    margin-left: -6rem !important;
  }
  .m-mxl-n6 {
    margin: -9rem !important;
  }
  .mt-mxl-n6,
  .my-mxl-n6 {
    margin-top: -9rem !important;
  }
  .mr-mxl-n6,
  .mx-mxl-n6 {
    margin-right: -9rem !important;
  }
  .mb-mxl-n6,
  .my-mxl-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-mxl-n6,
  .mx-mxl-n6 {
    margin-left: -9rem !important;
  }
  .m-mxl-n7 {
    margin: -12rem !important;
  }
  .mt-mxl-n7,
  .my-mxl-n7 {
    margin-top: -12rem !important;
  }
  .mr-mxl-n7,
  .mx-mxl-n7 {
    margin-right: -12rem !important;
  }
  .mb-mxl-n7,
  .my-mxl-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-mxl-n7,
  .mx-mxl-n7 {
    margin-left: -12rem !important;
  }
  .m-mxl-n8 {
    margin: -15rem !important;
  }
  .mt-mxl-n8,
  .my-mxl-n8 {
    margin-top: -15rem !important;
  }
  .mr-mxl-n8,
  .mx-mxl-n8 {
    margin-right: -15rem !important;
  }
  .mb-mxl-n8,
  .my-mxl-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-mxl-n8,
  .mx-mxl-n8 {
    margin-left: -15rem !important;
  }
  .m-mxl-n9 {
    margin: -18rem !important;
  }
  .mt-mxl-n9,
  .my-mxl-n9 {
    margin-top: -18rem !important;
  }
  .mr-mxl-n9,
  .mx-mxl-n9 {
    margin-right: -18rem !important;
  }
  .mb-mxl-n9,
  .my-mxl-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-mxl-n9,
  .mx-mxl-n9 {
    margin-left: -18rem !important;
  }
  .m-mxl-n10 {
    margin: -21rem !important;
  }
  .mt-mxl-n10,
  .my-mxl-n10 {
    margin-top: -21rem !important;
  }
  .mr-mxl-n10,
  .mx-mxl-n10 {
    margin-right: -21rem !important;
  }
  .mb-mxl-n10,
  .my-mxl-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-mxl-n10,
  .mx-mxl-n10 {
    margin-left: -21rem !important;
  }
  .m-mxl-auto {
    margin: auto !important;
  }
  .mt-mxl-auto,
  .my-mxl-auto {
    margin-top: auto !important;
  }
  .mr-mxl-auto,
  .mx-mxl-auto {
    margin-right: auto !important;
  }
  .mb-mxl-auto,
  .my-mxl-auto {
    margin-bottom: auto !important;
  }
  .ml-mxl-auto,
  .mx-mxl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1921px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.5rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-2 {
    margin: 1rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 1rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 1rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 1rem !important;
  }
  .m-xxl-3 {
    margin: 2rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 2rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 2rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 2rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 2rem !important;
  }
  .m-xxl-4 {
    margin: 3rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 3rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 3rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 3rem !important;
  }
  .m-xxl-5 {
    margin: 6rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 6rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 6rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 6rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 6rem !important;
  }
  .m-xxl-6 {
    margin: 9rem !important;
  }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 9rem !important;
  }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 9rem !important;
  }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 9rem !important;
  }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 9rem !important;
  }
  .m-xxl-7 {
    margin: 12rem !important;
  }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 12rem !important;
  }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 12rem !important;
  }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 12rem !important;
  }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 12rem !important;
  }
  .m-xxl-8 {
    margin: 15rem !important;
  }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 15rem !important;
  }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 15rem !important;
  }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 15rem !important;
  }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 15rem !important;
  }
  .m-xxl-9 {
    margin: 18rem !important;
  }
  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 18rem !important;
  }
  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 18rem !important;
  }
  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 18rem !important;
  }
  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 18rem !important;
  }
  .m-xxl-10 {
    margin: 21rem !important;
  }
  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 21rem !important;
  }
  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 21rem !important;
  }
  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 21rem !important;
  }
  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 21rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.5rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-2 {
    padding: 1rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 1rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 1rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 1rem !important;
  }
  .p-xxl-3 {
    padding: 2rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 2rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 2rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 2rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 2rem !important;
  }
  .p-xxl-4 {
    padding: 3rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 3rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 3rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 3rem !important;
  }
  .p-xxl-5 {
    padding: 6rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 6rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 6rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 6rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 6rem !important;
  }
  .p-xxl-6 {
    padding: 9rem !important;
  }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 9rem !important;
  }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 9rem !important;
  }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 9rem !important;
  }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 9rem !important;
  }
  .p-xxl-7 {
    padding: 12rem !important;
  }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 12rem !important;
  }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 12rem !important;
  }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 12rem !important;
  }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 12rem !important;
  }
  .p-xxl-8 {
    padding: 15rem !important;
  }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 15rem !important;
  }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 15rem !important;
  }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 15rem !important;
  }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 15rem !important;
  }
  .p-xxl-9 {
    padding: 18rem !important;
  }
  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 18rem !important;
  }
  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 18rem !important;
  }
  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 18rem !important;
  }
  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 18rem !important;
  }
  .p-xxl-10 {
    padding: 21rem !important;
  }
  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 21rem !important;
  }
  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 21rem !important;
  }
  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 21rem !important;
  }
  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 21rem !important;
  }
  .m-xxl-n1 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n2 {
    margin: -1rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -1rem !important;
  }
  .m-xxl-n3 {
    margin: -2rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -2rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -2rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -2rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -2rem !important;
  }
  .m-xxl-n4 {
    margin: -3rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -3rem !important;
  }
  .m-xxl-n5 {
    margin: -6rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -6rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -6rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -6rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -6rem !important;
  }
  .m-xxl-n6 {
    margin: -9rem !important;
  }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -9rem !important;
  }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -9rem !important;
  }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -9rem !important;
  }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -9rem !important;
  }
  .m-xxl-n7 {
    margin: -12rem !important;
  }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -12rem !important;
  }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -12rem !important;
  }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -12rem !important;
  }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -12rem !important;
  }
  .m-xxl-n8 {
    margin: -15rem !important;
  }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -15rem !important;
  }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -15rem !important;
  }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -15rem !important;
  }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -15rem !important;
  }
  .m-xxl-n9 {
    margin: -18rem !important;
  }
  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -18rem !important;
  }
  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -18rem !important;
  }
  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -18rem !important;
  }
  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -18rem !important;
  }
  .m-xxl-n10 {
    margin: -21rem !important;
  }
  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -21rem !important;
  }
  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -21rem !important;
  }
  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -21rem !important;
  }
  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -21rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1500px) {
  .text-mxl-left {
    text-align: left !important;
  }
  .text-mxl-right {
    text-align: right !important;
  }
  .text-mxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1921px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 1rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-mxl-1, .col-mxl-2, .col-mxl-3, .col-mxl-4, .col-mxl-5, .col-mxl-6, .col-mxl-7, .col-mxl-8, .col-mxl-9, .col-mxl-10, .col-mxl-11, .col-mxl-12, .col-mxl,
.col-mxl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1500px) {
  .col-mxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-mxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-mxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-mxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-mxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-mxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-mxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-mxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-mxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-mxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-mxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-mxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-mxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-mxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-mxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-mxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-mxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-mxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-mxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-mxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-mxl-first {
    order: -1;
  }
  .order-mxl-last {
    order: 13;
  }
  .order-mxl-0 {
    order: 0;
  }
  .order-mxl-1 {
    order: 1;
  }
  .order-mxl-2 {
    order: 2;
  }
  .order-mxl-3 {
    order: 3;
  }
  .order-mxl-4 {
    order: 4;
  }
  .order-mxl-5 {
    order: 5;
  }
  .order-mxl-6 {
    order: 6;
  }
  .order-mxl-7 {
    order: 7;
  }
  .order-mxl-8 {
    order: 8;
  }
  .order-mxl-9 {
    order: 9;
  }
  .order-mxl-10 {
    order: 10;
  }
  .order-mxl-11 {
    order: 11;
  }
  .order-mxl-12 {
    order: 12;
  }
  .offset-mxl-0 {
    margin-left: 0;
  }
  .offset-mxl-1 {
    margin-left: 8.33333%;
  }
  .offset-mxl-2 {
    margin-left: 16.66667%;
  }
  .offset-mxl-3 {
    margin-left: 25%;
  }
  .offset-mxl-4 {
    margin-left: 33.33333%;
  }
  .offset-mxl-5 {
    margin-left: 41.66667%;
  }
  .offset-mxl-6 {
    margin-left: 50%;
  }
  .offset-mxl-7 {
    margin-left: 58.33333%;
  }
  .offset-mxl-8 {
    margin-left: 66.66667%;
  }
  .offset-mxl-9 {
    margin-left: 75%;
  }
  .offset-mxl-10 {
    margin-left: 83.33333%;
  }
  .offset-mxl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1921px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.full-height {
  height: 100vh;
}

@media (min-width: 576px) {
  .full-height-sm {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .full-height-md {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .full-height-lg {
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .full-height-xl {
    height: 100vh;
  }
}

.s-full {
  width: 100%;
  height: 100%;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.bg-image {
  background: center center no-repeat;
  background-size: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.transition3s {
  transition: 0.3s ease all;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.image-cover, .blog_mini--variant .blog_mini_img img, .blog_mini_img img, .contact_banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flex-center, .blog_mini_body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.i-list {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
  position: relative;
}

.i-list_icon {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.absolute-fully-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-vertically-centered {
  position: absolute;
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-vertically-bottom {
  position: absolute;
  top: unset;
  bottom: 0%;
  transform: translateY(-50%);
}

.absolute-vertically-top {
  position: absolute;
  top: 0%;
  bottom: unset;
  transform: translateY(-50%);
}

.page-container {
  max-width: 1920px;
}

.content-container {
  max-width: 1600px;
}

body {
  overflow-x: hidden;
  font-style: normal;
  font-display: swap;
  font-family: 'Montserrat';
  box-sizing: border-box;
}

.text-color_white {
  color: white;
}

.text-color_black {
  color: black;
}

.text-color_main {
  color: #CE072B;
}

.text-color_light {
  color: #F1ECEB;
}

.text-color_dark {
  color: #2C2A26;
}

.text-color_shade {
  color: #B6B6B6;
}

.text-color_blue-darker {
  color: #162140;
}

.text-color_blue-lighter {
  color: #175B81;
}

.text-color_whatsapp {
  color: #51B444;
}

.background-color_white {
  background-color: white;
}

.background-color_black {
  background-color: black;
}

.background-color_main {
  background-color: #CE072B;
}

.background-color_light {
  background-color: #F1ECEB;
}

.background-color_dark {
  background-color: #2C2A26;
}

.background-color_shade {
  background-color: #B6B6B6;
}

.background-color_blue-darker {
  background-color: #162140;
}

.background-color_blue-lighter {
  background-color: #175B81;
}

.background-color_whatsapp {
  background-color: #51B444;
}

.text-size_12 {
  font-size: 12px;
  font-size: 12px !important;
}

@media (max-width: 1499.98px) {
  .text-size_12 {
    font-size: 11px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_12 {
    font-size: 11px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_12 {
    font-size: 11px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_12 {
    font-size: 11px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_12 {
    font-size: 11px !important;
  }
}

.text-size_14 {
  font-size: 14px;
  font-size: 14px !important;
}

@media (max-width: 1499.98px) {
  .text-size_14 {
    font-size: 12px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_14 {
    font-size: 12px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_14 {
    font-size: 12px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_14 {
    font-size: 12px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_14 {
    font-size: 12px !important;
  }
}

.text-size_16 {
  font-size: 16px;
  font-size: 16px !important;
}

@media (max-width: 1499.98px) {
  .text-size_16 {
    font-size: 12px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_16 {
    font-size: 12px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_16 {
    font-size: 12px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_16 {
    font-size: 12px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_16 {
    font-size: 12px !important;
  }
}

.text-size_17, .contact-form .el-checkbox__label {
  font-size: 17px;
  font-size: 17px !important;
}

@media (max-width: 1499.98px) {
  .text-size_17, .contact-form .el-checkbox__label {
    font-size: 13px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_17, .contact-form .el-checkbox__label {
    font-size: 13px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_17, .contact-form .el-checkbox__label {
    font-size: 13px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_17, .contact-form .el-checkbox__label {
    font-size: 13px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_17, .contact-form .el-checkbox__label {
    font-size: 13px !important;
  }
}

.text-size_18 {
  font-size: 18px;
  font-size: 18px !important;
}

@media (max-width: 1499.98px) {
  .text-size_18 {
    font-size: 13px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_18 {
    font-size: 13px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_18 {
    font-size: 13px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_18 {
    font-size: 13px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_18 {
    font-size: 13px !important;
  }
}

.text-size_20, .home_counters_body .text, .home_counters_body2 .text {
  font-size: 20px;
  font-size: 20px !important;
}

@media (max-width: 1499.98px) {
  .text-size_20, .home_counters_body .text, .home_counters_body2 .text {
    font-size: 14px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_20, .home_counters_body .text, .home_counters_body2 .text {
    font-size: 13px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_20, .home_counters_body .text, .home_counters_body2 .text {
    font-size: 13px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_20, .home_counters_body .text, .home_counters_body2 .text {
    font-size: 13px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_20, .home_counters_body .text, .home_counters_body2 .text {
    font-size: 13px !important;
  }
}

.text-size_25 {
  font-size: 25px;
  font-size: 25px !important;
}

@media (max-width: 1499.98px) {
  .text-size_25 {
    font-size: 17.5px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_25 {
    font-size: 15px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_25 {
    font-size: 14px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_25 {
    font-size: 14px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_25 {
    font-size: 14px !important;
  }
}

.text-size_28 {
  font-size: 28px;
  font-size: 28px !important;
}

@media (max-width: 1499.98px) {
  .text-size_28 {
    font-size: 19.6px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_28 {
    font-size: 16.8px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_28 {
    font-size: 14px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_28 {
    font-size: 13px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_28 {
    font-size: 13px !important;
  }
}

.text-size_30 {
  font-size: 30px;
  font-size: 30px !important;
}

@media (max-width: 1499.98px) {
  .text-size_30 {
    font-size: 21px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_30 {
    font-size: 18px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_30 {
    font-size: 17px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_30 {
    font-size: 17px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_30 {
    font-size: 17px !important;
  }
}

.text-size_35 {
  font-size: 35px;
  font-size: 35px !important;
}

@media (max-width: 1499.98px) {
  .text-size_35 {
    font-size: 24.5px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_35 {
    font-size: 21px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_35 {
    font-size: 21px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_35 {
    font-size: 21px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_35 {
    font-size: 21px !important;
  }
}

.text-size_40 {
  font-size: 40px;
  font-size: 40px !important;
}

@media (max-width: 1499.98px) {
  .text-size_40 {
    font-size: 30px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_40 {
    font-size: 30px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_40 {
    font-size: 30px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_40 {
    font-size: 30px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_40 {
    font-size: 30px !important;
  }
}

.text-size_50 {
  font-size: 50px;
  font-size: 50px !important;
}

@media (max-width: 1499.98px) {
  .text-size_50 {
    font-size: 35px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_50 {
    font-size: 30px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_50 {
    font-size: 30px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_50 {
    font-size: 30px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_50 {
    font-size: 30px !important;
  }
}

.text-size_60 {
  font-size: 60px;
  font-size: 60px !important;
}

@media (max-width: 1499.98px) {
  .text-size_60 {
    font-size: 42px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_60 {
    font-size: 36px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_60 {
    font-size: 30px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_60 {
    font-size: 30px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_60 {
    font-size: 30px !important;
  }
}

.text-size_100 {
  font-size: 100px;
  font-size: 100px !important;
}

@media (max-width: 1499.98px) {
  .text-size_100 {
    font-size: 70px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_100 {
    font-size: 60px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_100 {
    font-size: 50px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_100 {
    font-size: 40px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_100 {
    font-size: 40px !important;
  }
}

.text-size_165, .home_counters_body .title, .home_counters_body2 .title {
  font-size: 165px;
  font-size: 165px !important;
}

@media (max-width: 1499.98px) {
  .text-size_165, .home_counters_body .title, .home_counters_body2 .title {
    font-size: 120px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_165, .home_counters_body .title, .home_counters_body2 .title {
    font-size: 120px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_165, .home_counters_body .title, .home_counters_body2 .title {
    font-size: 120px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_165, .home_counters_body .title, .home_counters_body2 .title {
    font-size: 120px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_165, .home_counters_body .title, .home_counters_body2 .title {
    font-size: 120px !important;
  }
}

.text-size_sm-icon {
  font-size: 13px;
  font-size: 13px !important;
}

@media (max-width: 1499.98px) {
  .text-size_sm-icon {
    font-size: 13px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_sm-icon {
    font-size: 13px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_sm-icon {
    font-size: 13px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_sm-icon {
    font-size: 13px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_sm-icon {
    font-size: 13px !important;
  }
}

.text-size_md-icon, .header_social_icon {
  font-size: 20px;
  font-size: 20px !important;
}

@media (max-width: 1499.98px) {
  .text-size_md-icon, .header_social_icon {
    font-size: 15px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_md-icon, .header_social_icon {
    font-size: 15px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_md-icon, .header_social_icon {
    font-size: 15px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_md-icon, .header_social_icon {
    font-size: 15px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_md-icon, .header_social_icon {
    font-size: 15px !important;
  }
}

.text-size_lg-icon {
  font-size: 30px;
  font-size: 30px !important;
}

@media (max-width: 1499.98px) {
  .text-size_lg-icon {
    font-size: 21px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_lg-icon {
    font-size: 20px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_lg-icon {
    font-size: 20px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_lg-icon {
    font-size: 20px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_lg-icon {
    font-size: 20px !important;
  }
}

.text-size_xl-icon {
  font-size: 40px;
  font-size: 40px !important;
}

@media (max-width: 1499.98px) {
  .text-size_xl-icon {
    font-size: 30px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_xl-icon {
    font-size: 30px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_xl-icon {
    font-size: 30px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_xl-icon {
    font-size: 30px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_xl-icon {
    font-size: 30px !important;
  }
}

.text-size_xxl-icon {
  font-size: 60px;
  font-size: 60px !important;
}

@media (max-width: 1499.98px) {
  .text-size_xxl-icon {
    font-size: 42px !important;
  }
}

@media (max-width: 1199.98px) {
  .text-size_xxl-icon {
    font-size: 40px !important;
  }
}

@media (max-width: 991.98px) {
  .text-size_xxl-icon {
    font-size: 40px !important;
  }
}

@media (max-width: 767.98px) {
  .text-size_xxl-icon {
    font-size: 40px !important;
  }
}

@media (max-width: 575.98px) {
  .text-size_xxl-icon {
    font-size: 40px !important;
  }
}

.title-size_12 {
  font-size: 12px;
  font-size: 12px !important;
  line-height: 11px;
}

@media (max-width: 1499.98px) {
  .title-size_12 {
    font-size: 11px !important;
    line-height: 11px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_12 {
    font-size: 11px !important;
    line-height: 11px;
  }
}

@media (max-width: 991.98px) {
  .title-size_12 {
    font-size: 11px !important;
    line-height: 11px;
  }
}

@media (max-width: 767.98px) {
  .title-size_12 {
    font-size: 11px !important;
    line-height: 11px;
  }
}

@media (max-width: 575.98px) {
  .title-size_12 {
    font-size: 11px !important;
    line-height: 11px;
  }
}

.title-size_14 {
  font-size: 14px;
  font-size: 14px !important;
  line-height: 12px;
}

@media (max-width: 1499.98px) {
  .title-size_14 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_14 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 991.98px) {
  .title-size_14 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 767.98px) {
  .title-size_14 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 575.98px) {
  .title-size_14 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

.title-size_16 {
  font-size: 16px;
  font-size: 16px !important;
  line-height: 12px;
}

@media (max-width: 1499.98px) {
  .title-size_16 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_16 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 991.98px) {
  .title-size_16 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 767.98px) {
  .title-size_16 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

@media (max-width: 575.98px) {
  .title-size_16 {
    font-size: 12px !important;
    line-height: 12px;
  }
}

.title-size_17 {
  font-size: 17px;
  font-size: 17px !important;
  line-height: 17px;
}

@media (max-width: 1499.98px) {
  .title-size_17 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_17 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 991.98px) {
  .title-size_17 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 767.98px) {
  .title-size_17 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 575.98px) {
  .title-size_17 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

.title-size_18 {
  font-size: 18px;
  font-size: 18px !important;
  line-height: 18px;
}

@media (max-width: 1499.98px) {
  .title-size_18 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_18 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 991.98px) {
  .title-size_18 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 767.98px) {
  .title-size_18 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 575.98px) {
  .title-size_18 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

.title-size_20 {
  font-size: 20px;
  font-size: 20px !important;
  line-height: 20px;
}

@media (max-width: 1499.98px) {
  .title-size_20 {
    font-size: 14px !important;
    line-height: 13px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_20 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 991.98px) {
  .title-size_20 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 767.98px) {
  .title-size_20 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 575.98px) {
  .title-size_20 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

.title-size_25 {
  font-size: 25px;
  font-size: 25px !important;
  line-height: 25px;
}

@media (max-width: 1499.98px) {
  .title-size_25 {
    font-size: 17.5px !important;
    line-height: 17.5px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_25 {
    font-size: 15px !important;
    line-height: 14px;
  }
}

@media (max-width: 991.98px) {
  .title-size_25 {
    font-size: 14px !important;
    line-height: 14px;
  }
}

@media (max-width: 767.98px) {
  .title-size_25 {
    font-size: 14px !important;
    line-height: 14px;
  }
}

@media (max-width: 575.98px) {
  .title-size_25 {
    font-size: 14px !important;
    line-height: 14px;
  }
}

.title-size_28 {
  font-size: 28px;
  font-size: 28px !important;
  line-height: 28px;
}

@media (max-width: 1499.98px) {
  .title-size_28 {
    font-size: 19.6px !important;
    line-height: 19.6px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_28 {
    font-size: 16.8px !important;
    line-height: 16.8px;
  }
}

@media (max-width: 991.98px) {
  .title-size_28 {
    font-size: 14px !important;
    line-height: 13px;
  }
}

@media (max-width: 767.98px) {
  .title-size_28 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 575.98px) {
  .title-size_28 {
    font-size: 13px !important;
    line-height: 13px;
  }
}

.title-size_30 {
  font-size: 30px;
  font-size: 30px !important;
  line-height: 30px;
}

@media (max-width: 1499.98px) {
  .title-size_30 {
    font-size: 21px !important;
    line-height: 21px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_30 {
    font-size: 18px !important;
    line-height: 18px;
  }
}

@media (max-width: 991.98px) {
  .title-size_30 {
    font-size: 17px !important;
    line-height: 17px;
  }
}

@media (max-width: 767.98px) {
  .title-size_30 {
    font-size: 17px !important;
    line-height: 17px;
  }
}

@media (max-width: 575.98px) {
  .title-size_30 {
    font-size: 17px !important;
    line-height: 17px;
  }
}

.title-size_35 {
  font-size: 35px;
  font-size: 35px !important;
  line-height: 35px;
}

@media (max-width: 1499.98px) {
  .title-size_35 {
    font-size: 24.5px !important;
    line-height: 24.5px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_35 {
    font-size: 21px !important;
    line-height: 21px;
  }
}

@media (max-width: 991.98px) {
  .title-size_35 {
    font-size: 21px !important;
    line-height: 17.5px;
  }
}

@media (max-width: 767.98px) {
  .title-size_35 {
    font-size: 21px !important;
    line-height: 21px;
  }
}

@media (max-width: 575.98px) {
  .title-size_35 {
    font-size: 21px !important;
    line-height: 21px;
  }
}

.title-size_40 {
  font-size: 40px;
  font-size: 40px !important;
  line-height: 40px;
}

@media (max-width: 1499.98px) {
  .title-size_40 {
    font-size: 30px !important;
    line-height: 28px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_40 {
    font-size: 30px !important;
    line-height: 24px;
  }
}

@media (max-width: 991.98px) {
  .title-size_40 {
    font-size: 30px !important;
    line-height: 20px;
  }
}

@media (max-width: 767.98px) {
  .title-size_40 {
    font-size: 30px !important;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .title-size_40 {
    font-size: 30px !important;
    line-height: 30px;
  }
}

.title-size_50 {
  font-size: 50px;
  font-size: 50px !important;
  line-height: 50px;
}

@media (max-width: 1499.98px) {
  .title-size_50 {
    font-size: 35px !important;
    line-height: 35px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_50 {
    font-size: 30px !important;
    line-height: 30px;
  }
}

@media (max-width: 991.98px) {
  .title-size_50 {
    font-size: 30px !important;
    line-height: 25px;
  }
}

@media (max-width: 767.98px) {
  .title-size_50 {
    font-size: 30px !important;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .title-size_50 {
    font-size: 30px !important;
    line-height: 30px;
  }
}

.title-size_60 {
  font-size: 60px;
  font-size: 60px !important;
  line-height: 60px;
}

@media (max-width: 1499.98px) {
  .title-size_60 {
    font-size: 42px !important;
    line-height: 42px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_60 {
    font-size: 36px !important;
    line-height: 36px;
  }
}

@media (max-width: 991.98px) {
  .title-size_60 {
    font-size: 30px !important;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .title-size_60 {
    font-size: 30px !important;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .title-size_60 {
    font-size: 30px !important;
    line-height: 30px;
  }
}

.title-size_100 {
  font-size: 100px;
  font-size: 100px !important;
  line-height: 100px;
}

@media (max-width: 1499.98px) {
  .title-size_100 {
    font-size: 70px !important;
    line-height: 70px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_100 {
    font-size: 60px !important;
    line-height: 60px;
  }
}

@media (max-width: 991.98px) {
  .title-size_100 {
    font-size: 50px !important;
    line-height: 50px;
  }
}

@media (max-width: 767.98px) {
  .title-size_100 {
    font-size: 40px !important;
    line-height: 40px;
  }
}

@media (max-width: 575.98px) {
  .title-size_100 {
    font-size: 40px !important;
    line-height: 20px;
  }
}

.title-size_165 {
  font-size: 165px;
  font-size: 165px !important;
  line-height: 165px;
}

@media (max-width: 1499.98px) {
  .title-size_165 {
    font-size: 120px !important;
    line-height: 115.5px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_165 {
    font-size: 120px !important;
    line-height: 99px;
  }
}

@media (max-width: 991.98px) {
  .title-size_165 {
    font-size: 120px !important;
    line-height: 82.5px;
  }
}

@media (max-width: 767.98px) {
  .title-size_165 {
    font-size: 120px !important;
    line-height: 66px;
  }
}

@media (max-width: 575.98px) {
  .title-size_165 {
    font-size: 120px !important;
    line-height: 33px;
  }
}

.title-size_sm-icon {
  font-size: 13px;
  font-size: 13px !important;
  line-height: 13px;
}

@media (max-width: 1499.98px) {
  .title-size_sm-icon {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_sm-icon {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 991.98px) {
  .title-size_sm-icon {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 767.98px) {
  .title-size_sm-icon {
    font-size: 13px !important;
    line-height: 13px;
  }
}

@media (max-width: 575.98px) {
  .title-size_sm-icon {
    font-size: 13px !important;
    line-height: 13px;
  }
}

.title-size_md-icon {
  font-size: 20px;
  font-size: 20px !important;
  line-height: 20px;
}

@media (max-width: 1499.98px) {
  .title-size_md-icon {
    font-size: 15px !important;
    line-height: 15px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_md-icon {
    font-size: 15px !important;
    line-height: 15px;
  }
}

@media (max-width: 991.98px) {
  .title-size_md-icon {
    font-size: 15px !important;
    line-height: 15px;
  }
}

@media (max-width: 767.98px) {
  .title-size_md-icon {
    font-size: 15px !important;
    line-height: 15px;
  }
}

@media (max-width: 575.98px) {
  .title-size_md-icon {
    font-size: 15px !important;
    line-height: 15px;
  }
}

.title-size_lg-icon {
  font-size: 30px;
  font-size: 30px !important;
  line-height: 30px;
}

@media (max-width: 1499.98px) {
  .title-size_lg-icon {
    font-size: 21px !important;
    line-height: 21px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_lg-icon {
    font-size: 20px !important;
    line-height: 18px;
  }
}

@media (max-width: 991.98px) {
  .title-size_lg-icon {
    font-size: 20px !important;
    line-height: 20px;
  }
}

@media (max-width: 767.98px) {
  .title-size_lg-icon {
    font-size: 20px !important;
    line-height: 20px;
  }
}

@media (max-width: 575.98px) {
  .title-size_lg-icon {
    font-size: 20px !important;
    line-height: 20px;
  }
}

.title-size_xl-icon {
  font-size: 40px;
  font-size: 40px !important;
  line-height: 40px;
}

@media (max-width: 1499.98px) {
  .title-size_xl-icon {
    font-size: 30px !important;
    line-height: 28px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_xl-icon {
    font-size: 30px !important;
    line-height: 24px;
  }
}

@media (max-width: 991.98px) {
  .title-size_xl-icon {
    font-size: 30px !important;
    line-height: 20px;
  }
}

@media (max-width: 767.98px) {
  .title-size_xl-icon {
    font-size: 30px !important;
    line-height: 30px;
  }
}

@media (max-width: 575.98px) {
  .title-size_xl-icon {
    font-size: 30px !important;
    line-height: 30px;
  }
}

.title-size_xxl-icon {
  font-size: 60px;
  font-size: 60px !important;
  line-height: 60px;
}

@media (max-width: 1499.98px) {
  .title-size_xxl-icon {
    font-size: 42px !important;
    line-height: 42px;
  }
}

@media (max-width: 1199.98px) {
  .title-size_xxl-icon {
    font-size: 40px !important;
    line-height: 36px;
  }
}

@media (max-width: 991.98px) {
  .title-size_xxl-icon {
    font-size: 40px !important;
    line-height: 30px;
  }
}

@media (max-width: 767.98px) {
  .title-size_xxl-icon {
    font-size: 40px !important;
    line-height: 24px;
  }
}

@media (max-width: 575.98px) {
  .title-size_xxl-icon {
    font-size: 40px !important;
    line-height: 40px;
  }
}

.text_montserrat {
  font-style: normal;
  font-display: swap;
  font-family: 'Montserrat';
}

.hover_primary-color {
  transition: color 0.3s ease-in-out;
}

.hover_primary-color:hover, .hover_primary-color:focus-within {
  color: #CE072B;
}

.hover_white {
  transition: color 0.3s ease-in-out;
}

.hover_white:hover, .hover_white:focus-within {
  color: white;
}

.hover_opacity, .primary-button {
  transition: opacity 0.3s ease-in-out;
}

.hover_opacity:hover, .primary-button:hover, .hover_opacity:focus-within, .primary-button:focus-within {
  opacity: 0.6;
}

.font-weight_medium {
  font-weight: 500;
}

.font-weight_medium-r {
  font-weight: 600;
}

.font-weight_light {
  font-weight: 100;
}

.transition, .header li ul li, .header_mobile, .header_mobile *, .header_social, .header_social_title, .center-icon--hover, .blog_mini, .blog_mini img, .blog_mini_body, .home_solution_item .center-icon, .home_solution_item_action, .home_portfolio_item_title, .home_portfolio_item_hover {
  transition: all 0.3s ease-in-out;
}

.button-reset, .center-icon {
  border: none;
  outline: none;
}

.no-bg {
  background: transparent;
}

.opacity-70 {
  opacity: 70%;
}

.white-space {
  white-space: nowrap;
}

@media (max-width: 991.98px) {
  .white-space--reponsive {
    white-space: normal;
  }
}

.list-none {
  list-style-type: none;
  margin-left: 0em;
  margin-bottom: 0em;
  padding-left: 0;
  position: relative;
}

.center-icon > * {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.swiper-pagination {
  z-index: 10;
  bottom: 20px !important;
}

.swiper-pagination .swiper-pagination-bullet {
  margin: 0 7px !important;
  border-radius: 0;
  width: 10px;
  height: 6px;
  background-color: #2C2A26 !important;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #F1ECEB !important;
}

.gap-y-5 {
  gap: 25px 0;
}

.gap-y-10 {
  gap: 35px 0;
}

.k-icon-fix-pos svg {
  top: 0px !important;
}

@keyframes wave-anim {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.header {
  width: 100%;
  height: 100px;
  background: rgba(255, 255, 255, 0.7);
}

.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_logo_img {
  width: 190px;
  height: 100%;
}

@media (max-width: 1499.98px) {
  .header_logo_img {
    width: 120px;
  }
}

.header_nav {
  display: flex;
  font-size: 18px;
}

@media (max-width: 1499.98px) {
  .header_nav {
    font-size: 15px;
  }
}

.header_ul {
  gap: 12px;
  display: inline-flex;
}

.header_ul_sub {
  z-index: 100;
  background: #CE072B;
  padding: 15px 0px 30px 0px;
}

.header_ul_sub li {
  display: flex;
  font-weight: 100;
  color: white;
}

.header_ul_sub li a {
  width: 100%;
  padding: 0 50px 0 15px;
}

.header_li {
  font-weight: 600;
  position: relative;
  padding: 10px;
  font-size: 11px;
}

.header li ul {
  width: fit-content;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  color: white;
}

.header li ul li:hover {
  background: #B11C28;
}

.header li:hover ul,
.header li:focus-within ul {
  display: block;
}

@media (max-width: 928px) {
  .header {
    display: none;
  }
  .header_mobile {
    display: block !important;
  }
}

.header_mobile {
  height: 100px;
  display: none;
  padding: 20px 10px;
}

.header_mobile .is-active .hamburger-inner, .header_mobile .is-active .hamburger-inner::before, .header_mobile .is-active .hamburger-inner::after {
  background-color: white;
}

.header_mobile_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_mobile_locale {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.header_mobile img {
  width: 150px;
}

.header_mobile--open {
  width: 90vw;
  z-index: 1001;
  position: absolute;
  background: #2C2A26;
}

.header_mobile--open .header_mobile_wrapper {
  width: 100%;
}

.header_mobile--open .header_mobile_locale {
  z-index: -1;
  opacity: 1;
  position: relative;
}

.header_mobile--open .header_mobile_img {
  left: 50%;
  position: absolute;
  transform: scale(0.9) translateX(-50%);
}

.header_mobile--open .header_mobile_img img {
  filter: brightness(100);
}

@media (max-width: 928px) {
  .header--mobile-on {
    display: flex !important;
  }
}

.header--mobile {
  display: none;
  z-index: 1000;
  left: 0;
  top: 0;
  position: fixed;
  flex-direction: column;
  background: #2C2A26;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: rgba(255, 255, 255, 0.5);
}

@media (max-width: 928px) {
  .header--mobile {
    display: none;
  }
}

.header--mobile li ul {
  top: unset;
  position: relative;
  display: block !important;
}

.header--mobile .header_ul {
  gap: 2px;
  display: flex;
  font-size: 20px;
  flex-direction: column;
}

.header--mobile .header_ul_sub {
  padding: 0;
  background: transparent !important;
}

.header--mobile .header_ul_sub li {
  font-size: 17px;
}

.header_social:hover, .header_social:focus-within {
  color: #CE072B;
}

.header_social:hover .header_social_title, .header_social:focus-within .header_social_title {
  border-color: #CE072B;
}

.header_social_container {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.header_social_title {
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

.header_footer {
  width: 100%;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.footer {
  min-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.footer_logo {
  width: 100%;
  margin: auto;
  max-width: 200px;
}

@media (max-width: 991.98px) {
  .footer {
    height: auto;
    padding-top: 50px;
    margin-top: -50px;
  }
}

@media (max-width: 767.98px) {
  .footer {
    padding-top: 200px;
    margin-top: -150px;
    text-align: center;
  }
}

.center-icon {
  position: relative;
  width: 40px;
  height: 40px;
}

.center-icon--sm {
  width: 10px;
  height: 10px;
}

.center-icon--medium {
  width: 30px;
  height: 30px;
}

.center-icon--xxl {
  width: 110px;
  height: 110px;
}

.center-icon--round {
  overflow: hidden;
  border-radius: 100%;
}

.center-icon--hover:hover {
  background: #B6B6B6;
}

.center-icon > * {
  position: absolute;
}

.center-icon_text {
  letter-spacing: 0.1em;
}

.k-icon-fix-pos svg {
  top: 0px !important;
}

.primary-button {
  gap: 0 30px;
  height: 40px;
  display: flex;
  border: none;
  outline: none;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  align-items: center;
  background-color: #CE072B;
}

.blog_mini {
  height: 590vw;
  max-height: 590px;
  position: relative;
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.75);
}

.blog_mini:hover {
  background-color: #F1ECEB;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75) !important;
}

.blog_mini:hover .blog_mini_body {
  background-color: #F1ECEB;
}

.blog_mini:hover img {
  filter: grayscale(0.5);
}

.blog_mini--variant {
  box-shadow: unset;
  background-color: #F1ECEB;
}

.blog_mini--variant .blog_mini_body {
  text-align: left;
  align-items: start;
  justify-content: start;
}

.blog_mini--variant:hover {
  box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.75) !important;
}

.blog_mini--variant:hover .blog_mini_body {
  background-color: white;
}

.blog_mini--variant:hover img {
  filter: unset;
}

.blog_mini--variant .blog_mini_img img {
  padding: 20px;
}

.blog_mini_img {
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 65%;
  overflow: hidden;
}

.blog_mini_body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 35%;
  padding: 10px 15px;
  text-align: justify;
  flex-direction: column;
}

.blog_mini_listing {
  gap: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}

@media (max-width: 991.98px) {
  .blog_mini_listing {
    justify-content: center;
  }
}

.blog_mini_btn {
  z-index: 10;
  position: absolute;
  perspective: 1000px;
  top: 100%;
  left: 50%;
  justify-content: center;
  height: 30px;
  width: 60px;
  transition: transform 0.6s ease;
  transform: translate(-50%, -50%) rotateX(-90.1deg);
}

.blog_mini:hover {
  box-shadow: 0px 0px 17px 7px rgba(0, 0, 0, 0.75);
}

.blog_mini:hover .blog_mini_btn {
  transform: translate(-50%, -50%) rotateX(0deg);
}

.newsletter-input {
  width: 360px;
  height: 40px;
  display: flex;
  max-width: 70vw;
  align-items: center;
}

.newsletter-input .is-error input, .newsletter-input .is-error .el-input-group__append {
  border-color: #B11C28 !important;
}

.newsletter-input .el-form {
  width: 100%;
}

.newsletter-input .el-form-item {
  margin-bottom: 0;
}

.newsletter-input .el-input-group__append {
  background-color: transparent;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.newsletter-input .el-form-item__content {
  margin-left: 0 !important;
}

.newsletter-input .el-input {
  padding: 0px 0px;
}

.newsletter-input input {
  border-right: none;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.contact-form {
  color: #B6B6B6;
}

.contact-form input, .contact-form textarea {
  border-radius: 0;
  background-color: transparent;
}

.contact-form textarea {
  border: 1px solid rgba(182, 182, 182, 0.3);
}

.contact-form input {
  border: 0;
  border-bottom: 1px solid rgba(182, 182, 182, 0.3);
}

.contact-form input::placeholder {
  text-align: center;
  color: #B6B6B6;
}

.contact-form .el-form-item__label {
  float: unset;
  text-align: center;
  color: #B6B6B6;
}

.contact-form .el-checkbox__label {
  font-weight: 100;
}

.contact-form .el-checkbox {
  margin: 0 30px;
  display: flex;
}

.contact-form .el-checkbox span {
  white-space: normal;
  pointer-events: none;
}

.contact-form .el-checkbox .el-checkbox__inner {
  background-color: transparent;
  border: 1px solid rgba(182, 182, 182, 0.3);
}

.contact-form a {
  font-weight: 600;
}

@media (min-width: 20px) and (max-width: 500px) {
  body {
    -ms-overflow-style: none;
    /* Para IE y Edge */
    scrollbar-width: none;
    /* Para Firefox */
    overflow-y: scroll;
    /* Agrega desplazamiento vertical */
  }
  /* Ocultar la barra de desplazamiento en Firefox */
  body::-webkit-scrollbar {
    display: none;
  }
  .principal {
    margin-top: 5px !important;
  }
  .myCategory strong {
    font-size: 12px !important;
  }
  .content-g2 {
    width: 100% !important;
    font-size: 18px !important;
  }
  .content-g3 {
    width: 100% !important;
  }
  #contacto .font-weight_medium {
    font-size: 18px !important;
  }
  .mapas {
    margin-left: -10% !important;
  }
  .principal #first_counter .title {
    font-size: 36px !important;
  }
  .principal #first_counter .text {
    font-size: 22px !important;
  }
  .principal #second_counter2 .title {
    font-size: 36px !important;
  }
  .principal #second_counter2 .text {
    font-size: 22px !important;
  }
  .principal #third_counter3 .title {
    font-size: 36px !important;
  }
  .principal #third_counter3 .text {
    font-size: 22px !important;
  }
  .principal #fourth_counter .title {
    font-size: 36px !important;
  }
  .principal #fourth_counter .text {
    font-size: 22px !important;
  }
  .nuevos .col-9 {
    width: 90% !important;
  }
  .timeline-pc {
    display: none;
  }
  .txt {
    text-align: center;
  }
  .timeline-mobile {
    display: block !important;
  }
  .quien {
    position: absolute;
    margin-left: 3% !important;
    margin-top: 80% !important;
    font-size: 23px !important;
  }
  .contenedor {
    width: 100% !important;
  }
  .contenedor #first_counter1 {
    width: 100% !important;
  }
  .textos {
    width: 100% !important;
  }
  .textos .text-center {
    text-align: justify !important;
  }
  .home_portfolio {
    gap: 0 5px !important;
  }
  .home_portfolio_item {
    width: 100% !important;
    max-width: 100vw !important;
    height: 400px;
  }
  .home_portfolio_item img {
    width: 95% !important;
    height: 400px !important;
    padding-right: 30px !important;
  }
  .home_portfolio_item_title {
    top: 20% !important;
    left: 56% !important;
    height: 50% !important;
    width: 80% !important;
    opacity: 0.6;
    text-align: center;
    position: absolute;
    transform: rotate(90deg) translate(0%, 100px);
  }
  .home_portfolio_item_hover {
    color: white;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 0%;
    width: calc(100% - 60px);
    background: #E66D64;
    transform: translate(-100%, -50%);
  }
  .home_portfolio_item_hover > * {
    padding: 30px 55px 5px 30px !important;
  }
  .home_counters {
    position: absolute;
    width: 100%;
    height: 150px !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(206, 7, 43, 0.9);
  }
  .home_counters_body2 {
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  .home_counters_body2 .title {
    font-weight: 800;
    font-size: 40px !important;
  }
  .home_counters_body2 .text {
    font-weight: 400;
  }
  .home_solution {
    display: flex;
    font-size: 12px !important;
    align-items: center;
    justify-content: space-between;
  }
  .home_solution_item {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home_solution_item .center-icon {
    background-color: #F1ECEB;
  }
  .home_solution_item:hover .center-icon {
    background-color: #CE072B;
  }
  .home_solution_item:hover .home_solution_item_action {
    opacity: 1;
  }
  .home_solution_item_action {
    opacity: 0;
  }
}

@media (min-width: 20px) and (max-width: 500px) and (max-width: 991.98px) {
  .home_solution {
    flex-wrap: wrap;
    justify-content: center;
    gap: 70px;
  }
  .home_solution_item .center-icon {
    width: 70px;
    height: 70px;
  }
  .home_solution_item_action {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (min-width: 20px) and (max-width: 500px) and (max-width: 767.98px) {
  .home_solution {
    gap: 30px;
  }
}

@media (min-width: 20px) and (max-width: 500px) {
  .solution .img-g {
    width: 100% !important;
  }
  .solution .content-g {
    width: 100% !important;
  }
  .img-g {
    width: 100% !important;
  }
  .medida {
    width: 50% !important;
    display: block;
    margin: 0 auto;
  }
  .content-g {
    width: 100% !important;
  }
  .col-6 {
    width: 100% !important;
  }
  .certify {
    display: block !important;
  }
  .certify2 {
    display: none !important;
  }
  .img-a {
    width: 100% !important;
    margin: 0 auto;
  }
  .year2 {
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
  }
  .year2:hover {
    background-color: #c91634;
    color: white;
  }
  .mobile-content {
    background-color: #c91634;
    color: white;
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;
  }
}

.timeline-mobile {
  display: none;
}

.medida {
  width: 100%;
}

.certify2 {
  display: block;
}

.certify {
  display: none;
}

.img-a {
  width: 50%;
}

.img-g {
  width: 50%;
}

.content-g {
  width: 50%;
}

.home-carousel {
  min-height: 400px;
}

.home-carousel_text {
  left: 50%;
  width: 80vw;
  z-index: 11;
  bottom: 80px;
  font-size: 500;
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
}

.home-carousel_image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.home-carousel_image_container {
  height: 100%;
  width: 100%;
}

.home_counters {
  left: 0;
  z-index: 2;
  top: -30px;
  margin: 0;
  position: absolute;
  width: 100%;
  height: 70px !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(206, 7, 43, 0.9);
}

.home_counters #third_counter::before, .home_counters #second_counter::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.home_counters #second_counter {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.home_counters #second_counter::before {
  top: 0;
  right: 0;
}

.home_counters #third_counter {
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.home_counters #third_counter::before {
  top: 0;
  left: 0;
}

.home_counters_next {
  margin-top: 300px;
}

.home_counters_wrapper {
  line-height: 1;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.home_counters_container {
  margin-top: -10px;
  z-index: 1;
}

.home_counters_body {
  color: white;
  text-align: center;
  padding: 20px 0;
}

.home_counters_body .title {
  font-weight: 800;
}

.home_counters_body .text {
  font-weight: 400;
}

.home_counters_body2 {
  color: white;
  text-align: center;
  padding: 20px 0;
}

.home_counters_body2 .title {
  font-weight: 800;
}

.home_counters_body2 .text {
  font-weight: 400;
}

.home_counters_waves {
  z-index: -1;
  height: 100%;
  width: 100%;
}

.home_counters_waves, .home_counters_waves .wave {
  top: 0;
  left: 0;
  position: absolute;
}

.home_counters_waves .wave {
  width: 200%;
  height: 100%;
  background: url("/images/wave 1.svg") repeat-x;
}

.home_counters_waves .wave1 {
  top: -10px;
  animation: wave-anim 10s linear infinite;
}

.home_counters_waves .wave2 {
  top: 50px;
  opacity: 0.7;
  animation: wave-anim 20s linear infinite;
}

.home_counters_waves .wave3 {
  opacity: 0.5;
  top: 100px;
  animation: wave-anim 40s linear infinite;
}

.home_counters_waves .wave4 {
  opacity: 0.4;
  top: 130px;
  animation: wave-anim 5s linear infinite;
}

.home_solution {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home_solution_item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home_solution_item .center-icon {
  background-color: #F1ECEB;
}

.home_solution_item:hover .center-icon {
  background-color: #CE072B;
}

.home_solution_item:hover .home_solution_item_action {
  opacity: 1;
}

.home_solution_item_action {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .home_solution {
    flex-wrap: wrap;
    justify-content: center;
    gap: 70px;
  }
  .home_solution_item .center-icon {
    width: 70px;
    height: 70px;
  }
  .home_solution_item_action {
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 767.98px) {
  .home_solution {
    gap: 30px;
  }
}

.home_map {
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.home_portfolio {
  width: 100%;
  gap: 0 30px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  padding-bottom: 30px;
  justify-content: space-between;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.home_portfolio::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.home_portfolio::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
}

.home_portfolio::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  background: #000;
}

.home_portfolio::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
}

.home_portfolio_item {
  width: 1000px;
  max-width: 90vw;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
}

.home_portfolio_item img {
  width: 95%;
  height: 100%;
  padding-right: 30px;
  object-fit: cover;
}

.home_portfolio_item_title {
  top: 45%;
  left: 90%;
  height: 15%;
  width: 40%;
  opacity: 0.6;
  text-align: center;
  position: absolute;
  transform: rotate(90deg) translate(0%, 100px);
}

.home_portfolio_item_hover {
  color: white;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 00%;
  width: calc(100% - 30px);
  background: #E66D64;
  transform: translate(-100%, -50%);
}

.home_portfolio_item_hover > * {
  padding: 50px 100px;
}

.home_portfolio_item_button {
  width: 50px;
  height: 120%;
  position: absolute;
  right: 0%;
  top: 50%;
  background: #CE072B;
  transform: translate(0%, -50%);
}

.home_portfolio_item:hover .home_portfolio_item_hover {
  transform: translate(0%, -50%);
}

.home_portfolio_item:hover .home_portfolio_item_title {
  opacity: 1;
}

.principal {
  margin-top: -40px;
}

.contact_person-img {
  width: 195px;
  height: 195px;
}

.contact_person-info {
  margin: auto;
}

.contact_form {
  width: 100%;
  margin: auto;
  max-width: 900px;
}

.contact_banner {
  min-height: 300px;
}

@media (max-width: 991.98px) {
  .contact_person-img {
    width: 160px;
    height: 160px;
  }
}

@media (max-width: 767.98px) {
  .contact_person-info {
    width: fit-content;
  }
}

@media (max-width: 991.98px) {
  .call-to-action {
    text-align: center;
    justify-content: center;
  }
}

.floating-icons {
  position: fixed;
  right: 10px;
  /* Ajusta la distancia desde el borde derecho según tus necesidades */
  top: 50%;
  /* Ajusta la posición vertical según tus necesidades */
  transform: translateY(-50%);
  z-index: 9999;
  /* Asegúrate de que los iconos estén en un z-index superior para que estén por encima del contenido */
}

.floating-icons a {
  display: block;
  background-color: #d3d3d3;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  /* Ajusta el espacio entre los iconos según tus necesidades */
}

.floating-icons img {
  width: 30px;
  /* Ajusta el tamaño de los iconos según tus necesidades */
  height: 30px;
}

.floating-icons .whats:hover {
  background-color: #6FB253 !important;
}

.floating-icons a:hover {
  background-color: #C00020;
}

.timeline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.year {
  position: relative;
  padding: 7px 20px;
  border: 1px solid gray;
  border-radius: 5px;
}

.year:hover {
  background-color: #c91634;
  color: white;
}

.year-content {
  position: absolute;
  bottom: -230px;
  left: -120px;
  /* Ajusta el valor según sea necesario */
  width: 400px;
  /* Ajusta el ancho según sea necesario */
  height: 200px;
  /* Ajusta el ancho según sea necesario */
  padding: 15px;
  background-color: #C00020;
  border-radius: 15px;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
  z-index: 2;
  /* Asegura que el contenido esté por encima de los demás elementos */
}

#visual {
  position: absolute;
  top: 85px;
  right: -145px;
  /* Ajusta el valor según sea necesario */
  width: 400px;
  /* Ajusta el ancho según sea necesario */
  height: 200px;
  /* Ajusta el ancho según sea necesario */
  padding: 15px;
  background-color: #C00020;
  border-radius: 15px;
  text-align: left;
  z-index: 2;
  /* Asegura que el contenido esté por encima de los demás elementos */
  display: block;
  opacity: 1;
}

.timeline:hover #visual {
  opacity: 0;
}

.year:hover .year-content {
  visibility: visible;
  opacity: 1;
}

.year-content .content {
  margin-top: 5px;
}

.year-content::before {
  content: attr(data-title);
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.year .close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
  visibility: hidden;
}

.year-content:hover .close {
  visibility: visible;
  color: white;
}

.year .close:hover {
  color: white;
}

.hoome-valor .swiper {
  width: 100%;
  height: 200px;
}

.hoome-valor .swiper-slide {
  text-align: center;
  font-size: 15px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  /* Tamaño deseado para las imágenes */
  height: 50px;
  /* Tamaño deseado para las imágenes */
  overflow: hidden;
}

.swiper .swiper-button-next::after {
  display: none;
}

.swiper .swiper-button-next {
  background-image: url(/build/images/icons/right.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 40px !important;
  height: 40px !important;
}

.swiper .swiper-button-prev {
  background-image: url(/build/images/icons/left.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 40px !important;
  height: 40px !important;
}

.swiper .swiper-button-prev::after {
  display: none;
}

main {
  height: 70vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 2px;
}

section {
  transform-style: preserve-3d;
  position: relative;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
}

.parallax::after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateZ(-1px) scale(1.5);
  background-size: 100%;
  z-index: -1;
}

.bg::after {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/images/nosotros2.png);
  background-size: cover;
}

.myCategory .swiper-slide {
  text-align: center;
  font-size: 15px;
  background: #fff;
  height: calc((100% - 30px) / 2) !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-title {
  font-size: 12px;
}

.detalle .accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}

.detalle .accordion .accordion-item button[aria-expanded='true'] {
  border-bottom: 1px solid #000000;
}

.detalle .accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #000000;
  font-size: 1.15rem;
  font-weight: 600;
  border: none;
  background: none;
  outline: none;
}

.detalle .accordion button:hover,
.detalle .accordion button:focus {
  cursor: pointer;
  color: #0c0e0e;
}

.detalle .accordion button:hover::after,
.detalle .accordion button:focus::after {
  cursor: pointer;
  color: #0c0e0e;
  border: 1px solid #0c0e0e;
}

.detalle .accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.detalle .accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.detalle .accordion button .icon::before {
  display: block;
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.detalle .accordion button .icon::after {
  display: block;
  position: absolute;
  content: '';
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.detalle .accordion button[aria-expanded='true'] {
  color: #0c0e0e;
}

.detalle .accordion button[aria-expanded='true'] .icon::after {
  width: 0;
}

.detalle .accordion button[aria-expanded='true'] + .accordion-content {
  opacity: 1;
  max-height: 12em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.detalle .accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.detalle .accordion .accordion-content p {
  font-size: 12px;
  font-weight: 300;
  margin: 2em 0;
  text-align: justify;
}

.detalle .accordion .accordion-content li {
  font-size: 12px;
  font-weight: 300;
}

.detalle .accordion-item.active .accordion-content {
  height: 1000px !important;
  /* Cambia el valor a uno mayor o ajusta según sea necesario */
}

.detalle-p .accordion-content {
  height: 1500px !important;
  font-size: 12px;
  margin-top: -20px;
  padding-left: 37px;
  padding-right: 20px;
}

.detalle-p .accordion .accordion-item {
  border-bottom: 1px solid #e5e5e5;
}

.detalle-p .accordion .accordion-item button[aria-expanded='true'] {
  border-bottom: 1px solid #000000;
}

.detalle-p .accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #000000;
  font-size: 1.15rem;
  font-weight: 600;
  border: none;
  background: none;
  outline: none;
}

.detalle-p .accordion button:hover,
.detalle-p .accordion button:focus {
  cursor: pointer;
  color: #0c0e0e;
}

.detalle-p .accordion button:hover::after,
.detalle-p .accordion button:focus::after {
  cursor: pointer;
  color: #0c0e0e;
  border: 1px solid #0c0e0e;
}

.detalle-p .accordion button .accordion-title {
  padding: 1em 1.5em 1em 0;
}

.detalle-p .accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.detalle-p .accordion button .icon::before {
  display: block;
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.detalle-p .accordion button .icon::after {
  display: block;
  position: absolute;
  content: '';
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.detalle-p .accordion button[aria-expanded='true'] {
  color: #0c0e0e;
}

.detalle-p .accordion button[aria-expanded='true'] .icon::after {
  width: 0;
}

.detalle-p .accordion button[aria-expanded='true'] + .accordion-content {
  opacity: 1;
  max-height: 40em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.detalle-p .accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}

.detalle-p .accordion .accordion-content p {
  font-size: 12px;
  font-weight: 300;
  margin: 2em 0;
  text-align: justify;
}

.detalle-p .accordion .accordion-content li {
  font-size: 12px;
  font-weight: 300;
}

.detalle-p .accordion-item.active .accordion-content {
  height: 1500px !important;
  /* Cambia el valor a uno mayor o ajusta según sea necesario */
}

.flex-container {
  display: flex;
  align-items: stretch;
  /* Alinea ambos lados para que tengan la misma altura */
}

.circles {
  width: 125px;
  height: 125px;
  margin: 0 auto;
  background-color: #dbdcdda5;
  /* Color de fondo del círculo */
  border-radius: 50%;
  /* Valor 50% para crear un círculo */
  display: flex;
  justify-content: center;
  /* Centrar horizontalmente */
  align-items: center;
  /* Centrar verticalmente */
  position: relative;
  overflow: hidden;
}

.ocultar {
  opacity: 0;
}

.circles2 {
  background-color: #DC3545;
  /* Color de fondo del círculo */
  border-radius: 80%;
  /* Valor 50% para crear un círculo */
  color: white;
  padding: 10px 10px;
}

.circles:hover {
  background-color: #DC3545;
  /* Cambiamos el color de fondo al pasar el mouse */
}

.circles:hover ~ .ocultar {
  transition: opacity 0.3s ease;
  /* Transición de opacidad */
  opacity: 1;
  /* Al pasar el mouse, el ícono se vuelve visible */
}

#player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.5s;
  transform: scale(2.5);
  /* Aplicar zoom */
}

.accordion-buttons {
  width: 100%;
  text-align: left;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.accordion-buttons img {
  margin-right: 10px;
}

.accordion-contents {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  margin-top: -20px;
  padding-top: 5px;
  padding-left: 47px;
  padding-right: 20px;
}

.accordion-items.active .accordion-contents {
  max-height: 500px;
  /* Establece la altura máxima deseada */
}

.accordion-buttons .minus-icons {
  display: none;
}

.accordion-buttons[aria-expanded="true"] .minus-icons {
  display: inline;
}

.accordion-buttons[aria-expanded="true"] .plus-icons {
  display: none;
}

.quien {
  position: absolute;
  margin-left: 51%;
  margin-top: 3%;
  font-weight: 600;
  font-size: 33px;
}

.content-g {
  width: 50% !important;
  font-size: 18px !important;
}

.content-3 {
  width: 49% !important;
}
