//Only general css
.page-container {
    max-width: 1920px;
}
.content-container {
    max-width: 1600px;
}

body {
    overflow-x: hidden;
    @include Montserrat;
    box-sizing: border-box;
}

.text-color {
    @each $name, $val in $text-colors-array {
        &_#{$name} {
            color: $val;
        }
    }
}

.background-color {
    @each $name, $val in $background-colors-array {
        &_#{$name} {
            background-color: $val;
        }
    }
}

.text-size {
    @each $name, $val in $text-sizes-array {
        &_#{$name} {
            @include text-responsive(nth($val, 1), nth($val, 2));
        }
    }
}
.title-size {
    @each $name, $val in $text-sizes-array {
        &_#{$name} {
            @include title-responsive(nth($val, 1), nth($val, 2));
        }
    }
}

.text {
    &_montserrat {
        @include Montserrat;
    }
}

.hover {
    &_primary-color {
        transition: color 0.3s ease-in-out;
        &:hover,
        &:focus-within {
            color: $main-color;
        }
    }
    &_white {
        transition: color 0.3s ease-in-out;
        &:hover,
        &:focus-within {
            color: white;
        }
    }
    &_opacity {
        transition: opacity 0.3s ease-in-out;
        &:hover,
        &:focus-within {
            opacity: 0.6;
        }
    }
}

.font-weight {
    &_medium {
        font-weight: 500;
    }
    &_medium-r {
        font-weight: 600;
    }
    &_light {
        font-weight: 100;
    }
}

@mixin make-hover($property, $value) {
    transition: unquote($property) 0.3s ease-in-out;
    &:hover,
    &:focus-within {
        opacity: $value;
    }
}

.transition {
    transition: all 0.3s ease-in-out;
}

.button-reset {
    border: none;
    outline: none;
}

.no-bg {
    background: transparent;
}

.opacity {
    &-70 {
        opacity: 70%;
    }
}

.white-space {
    white-space: nowrap;
    &--reponsive {
        @include media-breakpoint-down(md) {
            white-space: normal;
        }
    }
}

.list-none {
    list-style-type: none;
    margin-left: 0em;
    margin-bottom: 0em;
    padding-left: 0;
    position: relative;
}

%absolute-fully-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.swiper-pagination {
    z-index: 10;
    bottom: 20px !important;

    .swiper-pagination-bullet {
        margin: 0 7px !important;
        border-radius: 0;
        width: 10px; height: 6px;
        background-color: $dark-color !important;
    }
    
    .swiper-pagination-bullet-active {
        background-color: $light-color !important;
    }
}

.gap-y-5 {
    gap: 25px 0;
}
.gap-y-10 {
    gap: 35px 0;
}

.k-icon-fix-pos {
    svg {
        top: 0px !important;
    }
}